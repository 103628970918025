import React from "react";
import "./bestPortfolioComponent.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import {ContextProviderComponent} from "../../contextProviderComponent";

import { Link } from 'gatsby';

import {
  isBrowser,
  isMobileOnly,
  isMobile,
  isTablet,
} from "react-device-detect";

import bestPortfolioImg from "../../../images/whysmartwealth/bestPortfolio.jpg";

class BestPortfolioComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      image: this.props.slice.primary.image.url ? this.props.slice.primary.image.url : bestPortfolioImg,
      title: this.props.slice.primary.title ? this.props.slice.primary.title.text : "",
      text1: this.props.slice.primary.text1 ? this.props.slice.primary.text1.text : "",
    }
  }

  componentDidMount() {
    
  }

  handleContentVisibility(inView, entry) {

    if (inView) {

      TM.to(
        entry.target.firstChild,
        0.8,
        { 
          opacity: 1,
          ease: Power2.easeInOut,
          delay: 0.0
        }
      );
    }
  }

  render() {
    return (
      <section id="bestPortfolio" className="bestPortfolio">
        <Observer
          threshold="0.1"
          onChange={(inView, entry) => this.handleContentVisibility(inView, entry)}
          className="wrapper"
        >
          <div className="bestPortfolio__cont">
            <div className="bestPortfolio__cont_left">
              <div className="bestPortfolio__cont_left__pageNumber">
                <span>{this.state.sectionNumber}</span>
              </div>
              <div className="bestPortfolio__cont_left__text">
                <span>{isMobileOnly || (isTablet && (window.innerHeight > window.innerWidth)) ? <span style={{marginRight:'24px'}}>{this.state.sectionNumber}</span> : ''}{this.state.sectionName}</span>
                {this.state.title && <h2>{this.state.title}</h2>}
                {this.state.text1 && <p>{this.state.text1}</p>}
              </div>
            </div>
            <div className="bestPortfolio__cont_right">
              <div className="bestPortfolio__cont_right__image" 
                style={{background: "url('" + this.state.image + "')", backgroundSize: "contain", backgroundColor: "white", backgroundRepeat: "no-repeat", backgroundPositionX: '100%'}}>
              </div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
BestPortfolioComponent.contextType = ContextProviderComponent;
export default BestPortfolioComponent;
