import React from "react";
import "./strategyComponent.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";
import { ContextProviderComponent } from "../../contextProviderComponent";
import strategy_manager_img from "../../../images/institutionalclients/strategy_manager_img.png";
import strategy_overview_img from "../../../images/institutionalclients/strategy_overview_img.png";
import strategy_allocation_img from "../../../images/institutionalclients/strategy_allocation_img.png";
import strategy_analysis_img from "../../../images/institutionalclients/strategy_analysis_img.png";
import strategy_analysis_img_2 from "../../../images/institutionalclients/strategy_analysis_img_2.png";
import strategy_analysis_img_3 from "../../../images/institutionalclients/strategy_analysis_img_3.png";
import strategy_analysis_img_4 from "../../../images/institutionalclients/strategy_analysis_img_4.png";
import StrategyBasicTab from "./strategyBasicTab/strategyBasicTab";
import StrategySliderTab from "./strategySliderTab/strategySliderTab";

class StrategyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_title.text || "",
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.description.text,
      activeTab: 0
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.subtitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
    }
  }

  changeTab = (number) => {
    TM.to(this.tabContainer, 0.3, {
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ activeTab: number });
        TM.to(this.tabContainer, 0.3, {
          opacity: 1,
          ease: Power2.easeOut,
          delay: 0.1
        });
      }.bind(this)
    });
  };

  render() {
    return (
      <section
        id="strategyComponent"
        className={`strategyComponent ${this.state.orientation == "Left" ? "" : "strategyComponent_white"}`}>
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="strategyComponent__cont">
            <div className="strategyComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="strategyComponent__cont_content">
              <span className="strategyComponent__cont_content_pageTitle">
                {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                  <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                ) : (
                  ""
                )}
                {this.state.sectionName}
              </span>
              <h2
                ref={(ref) => {
                  this.title = ref;
                }}
                className="strategyComponent__cont_content_title">
                {this.state.title}
              </h2>
              <h3
                ref={(ref) => {
                  this.subtitle = ref;
                }}
                className="strategyComponent__cont_content_subtitle">
                {this.state.subtitle}
              </h3>
              <div className="strategyComponent__cont_content_tabsContainer">
                <div
                  className={`strategyComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 0 ? "strategyComponent__cont_content_tabsContainer_tab-active" : ""
                  }`}
                  onClick={() => this.changeTab(0)}>
                  {this.context.lang == "de-ch" ? "Strategie Manager" : "Strategy manager"}
                </div>
                <div
                  className={`strategyComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 1 ? "strategyComponent__cont_content_tabsContainer_tab-active" : ""
                  }`}
                  onClick={() => this.changeTab(1)}>
                  {this.context.lang == "de-ch" ? "Strategie Überblick" : "Strategy overview"}
                </div>
                <div
                  className={`strategyComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 2 ? "strategyComponent__cont_content_tabsContainer_tab-active" : ""
                  }`}
                  onClick={() => this.changeTab(2)}>
                  {this.context.lang == "de-ch" ? "Strategie Allokation" : "Strategy allocation"}
                </div>
                <div
                  className={`strategyComponent__cont_content_tabsContainer_tab ${
                    this.state.activeTab == 3 ? "strategyComponent__cont_content_tabsContainer_tab-active" : ""
                  }`}
                  onClick={() => this.changeTab(3)}>
                  {this.context.lang == "de-ch" ? "Strategie Analyse" : "Strategy analysis"}
                </div>
              </div>
              <div
                ref={(ref) => {
                  this.tabContainer = ref;
                }}>
                {this.state.activeTab == 0 && (
                  <StrategyBasicTab
                    title={this.context.lang == "de-ch" ? "Strategie Manager" : "Strategy manager"}
                    text={
                      this.context.lang == "de-ch"
                        ? "Der Kunde bestimmt seine Strategie basierend auf Thema, Währung, Assetklasse, Risikostufe, Region oder Ansatz."
                        : "The client determines his strategy based on theme, currency, asset class, risk level, region or approach."
                    }
                    image={strategy_manager_img}
                  />
                )}
                {this.state.activeTab == 1 && (
                  <StrategyBasicTab
                    title={this.context.lang == "de-ch" ? "Strategie Überblick" : "Strategy overview"}
                    text={
                      this.context.lang == "de-ch"
                        ? "Alle relevanten Informationen zur ausgesuchten Strategie auf einen Blick. Berichte, tagesaktuelle Factsheets werden automatisch erstellt."
                        : "All relevant information on the selected strategy at a glance. Reports, daily updated factsheets are generated automatically."
                    }
                    image={strategy_overview_img}
                  />
                )}
                {this.state.activeTab == 2 && (
                  <StrategyBasicTab
                    title={this.context.lang == "de-ch" ? "Strategie Allokation" : "Strategy allocation"}
                    text={
                      this.context.lang == "de-ch"
                        ? "Alle Informationen zur Zusammensetzung, aktuelle Gewichtung, sowie zur Strategische Asset Allokation"
                        : "All information on composition, current weighting and strategic asset allocation."
                    }
                    image={strategy_allocation_img}
                  />
                )}
                {this.state.activeTab == 3 && (
                  <StrategySliderTab
                    title={this.context.lang == "de-ch" ? "Strategie Analyse" : "Strategy analysis"}
                    text={
                      this.context.lang == "de-ch"
                        ? "Informationen zu aktuellen und historischen Transaktionen auf Einzel- und Portfolioebene, zum Risiko, Renditeentwicklung....."
                        : "Information on current and historical transactions at individual and portfolio level, on risk, return development....."
                    }
                    imageSlides={[
                      strategy_analysis_img,
                      strategy_analysis_img_2,
                      strategy_analysis_img_3,
                      strategy_analysis_img_4
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
StrategyComponent.contextType = ContextProviderComponent;
export default StrategyComponent;
