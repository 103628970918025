// Context.js
import React from "react"

const defaultContextValue = {
  activeSection: 0,
}

export const ContextProviderComponent = React.createContext(
  defaultContextValue// default value
);
