import React from "react";
import "./blogTextBlock.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { Link } from "gatsby";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

class BlogTextBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title1 || "",
      text: this.props.slice.primary.textblock || ""
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.text, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="blogTextBlock" className="blogTextBlock">
        <Observer threshold="0.0" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="blogTextBlock_cont" ref={(ref) => (this.text = ref)}>
            <h2>{this.state.title?.text}</h2>
            <p>{this.state.text?.text}</p>
          </div>
        </Observer>
      </section>
    );
  }
}
BlogTextBlock.contextType = ContextProviderComponent;
export default BlogTextBlock;
