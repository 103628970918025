import React from 'react';
import './outstandingProductsComponent.scss';
import Observer from 'react-intersection-observer';
import {isBrowser, isMobileOnly, isMobile, isTablet, isDesktop} from 'react-device-detect';

import {TweenMax as TM, Power1, Power2, Power3, gsap} from 'gsap/all';
import {ContextProviderComponent} from '../../contextProviderComponent';

class OutstandingProductsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionNumber: this.props.slice.primary.section_number.text,
            sectionName: this.props.slice.primary.section_title.text,
            title: this.props.slice.primary.title.text,
            background_image: this.props.slice.primary.background_image.url,
            button_text: this.props.slice.primary.cta_text.text,
            buttonLink: this.props.slice.primary.button_link ? this.props.slice.primary.button_link.url : '',
        };
    }

    componentDidMount() {
        this.setState({index: 0});
        gsap.set([this.title, this.button], {opacity: 0, y: 50});
    }

    handleContentVisibility(inView, entry) {
        if (inView) {
            TM.to(this.title, 1, {
                opacity: 1,
                y: 0,
                ease: Power3.easeOut,
                delay: 0.4,
            });
            TM.to(this.button, 1, {
                opacity: 1,
                y: 0,
                ease: Power3.easeOut,
                delay: 0.5,
            });
        }
    }

    render() {
        return (
            <section
                id="outstandingProducts"
                className="outstandingProducts"
                style={{background: `url("${this.state.background_image}")`}}
            >
                <Observer
                    threshold="0.1"
                    onChange={(inView, entry) => this.handleContentVisibility(inView, entry)}
                    className="wrapper"
                >
                    <div className="outstandingProducts__cont">
                        <div className="outstandingProducts__cont_pageNumber">
                            <span>{this.state.sectionNumber}</span>
                        </div>
                        <div className="outstandingProducts__cont_content">
                            <span className="outstandingProducts__cont_content_pageTitle">
                                {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                                    <span style={{marginRight: '24px'}}>{this.state.sectionNumber}</span>
                                ) : (
                                    ''
                                )}
                                {this.state.sectionName}
                            </span>
                            <h2
                                ref={ref => {
                                    this.title = ref;
                                }}
                                className="outstandingProducts__cont_content_title"
                            >
                                {this.state.title}
                            </h2>
                            <div
                                ref={ref => {
                                    this.button = ref;
                                }}
                                id="heroTextBtn"
                                className="hero__cont_button button outstandingProducts__cont_content_button"
                                onClick={() =>
                                    this.state.buttonLink != ''
                                        ? window.open(this.state.buttonLink, '_blank')
                                        : this.context.navigateClickCallback(4)
                                }
                            >
                                {this.state.button_text && <p>{this.state.button_text}</p>}
                            </div>
                        </div>
                    </div>
                </Observer>
            </section>
        );
    }
}
OutstandingProductsComponent.contextType = ContextProviderComponent;
export default OutstandingProductsComponent;
