import React from "react";
import $ from "jquery";
import Observer from "react-intersection-observer";
import "./losungComponent.scss";

import disableScroll from "disable-scroll";
import { TweenMax as TM, Power1, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { isBrowser, isDesktop, isMobileOnly, isMobile, isTablet, withOrientationChange } from "react-device-detect";

import closeImg from "../../../images/icons/hide.svg";

class LosungComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      sectionTitle: this.props.slice.primary.section_title.text || "",
      sectionText: this.props.slice.primary.section_text.text || "",
      items: this.props.slice.items || [],
      index: 0
    };
  }

  componentDidMount() {
    $(".losungSection__cont_content__items--col").on("mouseenter", function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      TM.to($(".losungSection__cont_content"), 0.5, {
        opacity: 0,
        ease: Power2.easeInOut,
        delay: 0.2
      });
    });

    $(".losungSection__cont_content__items--col").on("mouseleave", function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();

      TM.to($(".losungSection__cont_content"), 0.5, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.2
      });
    });

    $(".losungSection__cont_content__items--col").on(
      "click",
      function(event) {
        let losungIndex = $(event.target)
          .closest(".losungSection__cont_content__items--col")
          .attr("data");
        this.setState({ index: losungIndex });
        this.showDetail(losungIndex);
      }.bind(this)
    );

    $(".losungDetail__close, .losungDetail .closeBtn").on(
      "click",
      function(event) {
        this.hideDetail();
      }.bind(this)
    );

    /////////////////////////////////////////////////////////////////////////////////////////

    $(".losungSection__cont_content__itemsRespo--col").on(
      "click",
      function(event) {
        let losungIndex = $(event.target)
          .closest(".losungSection__cont_content__itemsRespo--col")
          .attr("data");
        this.setState({ index: losungIndex });
        this.showDetail(losungIndex);
      }.bind(this)
    );

    $(".losungDetail__close, .losungDetail .closeBtn").on(
      "click",
      function(event) {
        this.hideDetail();
      }.bind(this)
    );
  }

  componentWillUnmount() {
    this.enableScroll();
  }

  showDetail(losungIndex) {
    TM.to($("header"), 0.4, {
      y: "-100%",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onStart: function() {
        this.disableScroll();
        $("#fp-nav").fadeOut();
      }.bind(this),
      onComplete: function() {
        $(".losungDetail").fadeIn();
        TM.to($(".losungDetail__cont"), 0.4, {
          x: "0px",
          ease: Power2.easeOut,
          delay: 0.2
        });
      }
    });
  }

  hideDetail() {
    TM.to($(".losungDetail__cont"), 0.4, {
      x: "-100%",
      ease: Power2.easeOut,
      onComplete: function() {
        $(".losungDetail").fadeOut();
        TM.to($("header"), 0.4, {
          y: "0%",
          opacity: 1,
          ease: Power2.easeOut,
          onComplete: function() {
            this.enableScroll();
            $("#fp-nav").fadeIn();
          }.bind(this)
        });
      }.bind(this)
    });
  }

  disableScroll() {
    if (isDesktop && !isTablet) {
      $("html, body").bind("wheel", function() {
        return false;
      });
    } else {
      $("html, body").css("overflow", "hidden");
    }
  }

  enableScroll() {
    if (isDesktop && !isTablet) {
      $("html, body").off("wheel");
    } else {
      $("html, body").css("overflow", "");
    }
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    if (isTablet && window.innerWidth > window.innerHeight) {
      $(".losungDetail").css("position", "fixed");
    }

    return (
      <section id="losungSection" className="losungSection">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="losungSection__cont">
            <div className="losungSection__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>

            <div className="losungSection__cont_content">
              <div style={{ display: isMobileOnly || isTablet ? "flex" : "none" }}>
                <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                <span>{this.state.sectionName}</span>
              </div>
              <div style={{ display: isDesktop && !isTablet ? "flex" : "none" }}>
                <span style={{ marginRight: "29px" }}>{this.state.sectionNumber}</span> <span>{this.state.sectionName}</span>
              </div>
              {this.state.sectionTitle && <h2>{this.state.sectionTitle}</h2>}
              {this.state.sectionText && <p>{this.state.sectionText}</p>}
            </div>

            <div
              style={{ display: (isDesktop && !isTablet) || (isTablet && window.innerWidth > 1024) ? "flex" : "none" }}
              className="losungSection__cont_content__items">
              {this.state.items.map((columnItem, i) => {
                return (
                  <div className="losungSection__cont_content__items--col" data={i}>
                    <div className={"losungSection__cont_content__items--col" + (i + 1)}>
                      <div className={"losungSection__cont_content__items--col" + (i + 1) + "-overlay"}></div>
                      <div className={"losungSection__cont_content__items--col" + (i + 1) + "-overlay2"}></div>
                    </div>
                    <div className={"losungSection__cont_content__items--col" + (i + 1) + "-button"}>
                      <img
                        alt="button"
                        className={"losungSection__cont_content__items--col" + (i + 1) + "-button-img"}
                        src={columnItem.logo.url}></img>
                      <span>{columnItem.item_title.text}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div
              style={{ display: isMobileOnly || (isTablet && window.innerWidth <= 1024) ? "flex" : "none" }}
              className="losungSection__cont_content__itemsRespo">
              {this.state.items.map((columnItem, i) => {
                return (
                  <div
                    className={
                      "losungSection__cont_content__itemsRespo--col losungSection__cont_content__itemsRespo--col" + (i + 1)
                    }
                    data={i}>
                    <div title={columnItem.item_title.text} className="">
                      <img
                        alt="button"
                        className={"losungSection__cont_content__itemsRespo--col" + (i + 1) + "-img"}
                        src={columnItem.logo.url}></img>
                      <span>{columnItem.item_title.text}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Observer>

        <div className="losungDetail">
          <div className="losungDetail__cont">
            <div className="losungDetail__cont__header">
              <img src={closeImg} alt="smartwealth close icon" className="closeBtn"></img>
              <span className="losungDetail__cont__header_text losungDetail__cont__header_text-colorful">
                {this.state.sectionName}
              </span>
              <span className="losungDetail__cont__header_text">•</span>
              <span className="losungDetail__cont__header_text">
                {this.state.items[this.state.index] ? this.state.items[this.state.index].item_title.text : ""}
              </span>
            </div>

            <div className="losungDetail__cont__content">
              <h3>{this.state.items[this.state.index] ? this.state.items[this.state.index].item_title.text : ""}</h3>

              {this.state.items[this.state.index] &&
                this.state.items[this.state.index].item_text.raw.map((paragraph, i) => {
                  return <p key={i}>{paragraph.text}</p>;
                })}
            </div>
          </div>

          <div className="losungDetail__close"></div>
        </div>
      </section>
    );
  }
}
LosungComponent.contextType = ContextProviderComponent;
export default LosungComponent;
