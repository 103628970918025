import React from 'react';
import Observer from 'react-intersection-observer';
import logo from '../../images/logo/logo.png';
import closeImg from '../../images/icons/close.svg';
import {Link} from 'gatsby';
import './header.scss';
import ProgressBar from 'react-scroll-progress-bar';

import {isBrowser} from 'react-device-detect';

import {TweenMax as TM, Power2} from 'gsap/all';
import {ContextProviderComponent} from '../contextProviderComponent';
import LanguageSwitcher from './LanguageSwitcher';
import OnboardingModalComponent from '../onboardingModalComponent/onboardingModalComponent';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {InputLabel} from '@material-ui/core';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSection: -1,
            showOnboardingModal: false,
            progressBar: this.props.progressBar ? this.props.progressBar : false,
            pageData: {
                item1: {text: ''},
                item2: {text: ''},
                item3: {text: ''},
                item4: {text: ''},
                item5: {text: ''},
                item6: {text: ''},
                button: {text: ''},
                cookie_consent: {text: []},
                solutions: [],
            },
            activeDocMeta: {
                lang: '',
                alternateLanguages: [{lang: '', type: ''}],
            },
        };
        this.classes = makeStyles(theme => ({
            formControl: {
                //margin: theme.spacing(1),
                //minWidth: 120,
            },
            select: {
                color: 'white',
                fontFamily: 'Avenir',
                fontSize: '12px',
                fontWeight: 800,
                letterSpacing: '0.04em',
            },
            icon: {
                fill: 'white',
            },
            input: {
                minHeight: 'unset',
            },
            menuItem: {
                color: '#17181D',
                fontFamily: 'Avenir',
                fontSize: '12px',
                fontWeight: 800,
                letterSpacing: '0.04em',
            },
        }));
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent);

        let header = document.getElementsByClassName('header');

        TM.fromTo(
            header,
            1,
            {opacity: 0},
            {
                opacity: 1,
                ease: Power2.easeOut,
                delay: 0.2,
            }
        );
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.activeSection !== this.props.activeSection) {
            this.setState({activeSection: nextProps.activeSection});
        }
        if (nextProps.body) {
            this.setState({pageData: {...nextProps.body.data}});
        }
        if (nextProps.activeDocMeta) {
            this.setState({
                activeDocMeta: {
                    lang: nextProps.activeDocMeta.lang,
                    alternateLanguages: [...nextProps.activeDocMeta.alternateLanguages],
                },
            });
        }
    }

    setShowOnboardingModal = val => {
        this.setState({showOnboardingModal: val});
    };

    handleLangChange = event => {
        switch (event.target.value) {
            case this.state.pageData.solutions[0].solution_name.text:
                this.context.navigateClickCallback(11);
                break;
            case this.state.pageData.solutions[1].solution_name.text:
                this.context.navigateClickCallback(12);
                break;
            case this.state.pageData.solutions[2].solution_name.text:
                this.context.navigateClickCallback(13);
                break;
            default:
                break;
        }
    };

    render() {
        const solutionOptions = this.state.pageData.solutions?.map(solution => {
            return (
                <MenuItem value={solution.solution_name.text} className={this.classes.menuItem} disableUnderline={true}>
                    {solution.solution_name.text}
                </MenuItem>
            );
        });
        return (
            <header
                className="header"
                style={{
                    background: this.props.headerBackground,
                    height: this.props.height,
                }}
            >
                <Observer threshold="1" className="wrapper">
                    <div className="header__cont">
                        <div className="header__cont__firstCol">
                            <img
                                className="header__cont__firstCol_logo"
                                alt={'logo smartwealth'}
                                src={isBrowser ? logo : logo}
                                onClick={() => this.context.navigateClickCallback(99)}
                            ></img>
                        </div>

                        <div className="header__cont_secondCol">
                            <div className="header__cont_secondCol_menu">
                                <span
                                    onClick={() => this.context.navigateClickCallback(0)}
                                    className={
                                        'header__cont_secondCol_menu_item ' +
                                        (this.state.activeSection == 0 ? 'header__cont_secondCol_menu_item_active' : '')
                                    }
                                >
                                    {this.state.pageData.item1.text}
                                </span>

                                <InputLabel variant="standard" id="solutions-selector-1-label">
                                    Solutions
                                </InputLabel>
                                <Select
                                    className={this.classes.select}
                                    labelId="solutions-selector-1-label"
                                    id="solutions-selector-1"
                                    value={''}
                                    label={this.state.pageData.item2.text}
                                    onChange={this.handleLangChange}
                                    disableUnderline={true}
                                    displayEmpty
                                    inputProps={{
                                        classes: {
                                            icon: this.classes.icon,
                                            root: this.classes.input,
                                        },
                                        'aria-label': 'Without label',
                                    }}
                                >
                                    {/* <MenuItem selected disabled value="" className={this.classes.menuItem} disableUnderline={true}>
                    {this.state.pageData.item2.text}
                  </MenuItem> */}
                                    {solutionOptions}
                                </Select>

                                <span
                                    onClick={() => this.context.navigateClickCallback(2)}
                                    className={
                                        'header__cont_secondCol_menu_item ' +
                                        (this.state.activeSection == 2 ? 'header__cont_secondCol_menu_item_active' : '')
                                    }
                                >
                                    {this.state.pageData.item3.text}
                                </span>

                                {false && (
                                    <span
                                        onClick={() => console.log(3)}
                                        className={
                                            'header__cont_secondCol_menu_item ' +
                                            (this.state.activeSection == 3 ? 'header__cont_secondCol_menu_item_active' : '')
                                        }
                                    >
                                        {this.state.pageData.item4.text}
                                    </span>
                                )}

                                <span
                                    onClick={() => this.context.navigateClickCallback(5)}
                                    className={
                                        'header__cont_secondCol_menu_item ' +
                                        (this.state.activeSection == 5 ? 'header__cont_secondCol_menu_item_active' : '')
                                    }
                                >
                                    {this.state.pageData.item6?.text}
                                </span>

                                <span
                                    onClick={() => this.context.navigateClickCallback(4)}
                                    className={
                                        'header__cont_secondCol_menu_item ' +
                                        (this.state.activeSection == 4 ? 'header__cont_secondCol_menu_item_active' : '')
                                    }
                                >
                                    {this.state.pageData.item5.text}
                                </span>
                            </div>
                            <div
                                onClick={() =>
                                    window.open(
                                        this.state.activeDocMeta.lang == 'de-ch'
                                            ? 'https://app.smartwealth.ch/de/dashboard/calculator'
                                            : 'https://app.smartwealth.ch/en/dashboard/calculator',
                                        '_blank'
                                    )
                                }
                                className="header__cont_wrapper"
                            >
                                <div className="button header__cont_wrapper_button">
                                    <p className="header__cont_wrapper_button_p">{this.state.pageData.button.text}</p>
                                </div>
                            </div>
                            <Link
                                to={
                                    this.state.activeDocMeta.lang == 'de-ch'
                                        ? 'https://app.smartwealth.ch/de/login'
                                        : 'https://app.smartwealth.ch/en/login'
                                }
                                target="_blank"
                            >
                                <div className="button header__cont_wrapper_loginButton">
                                    <p className="header__cont_wrapper_loginButton_p">Login</p>
                                </div>
                            </Link>

                            <div className="menu">
                                <LanguageSwitcher activeDocMeta={this.state.activeDocMeta} />
                            </div>
                        </div>
                    </div>
                </Observer>
                <OnboardingModalComponent
                    showOnboardingModal={this.state.showOnboardingModal}
                    setShowOnboardingModal={this.setShowOnboardingModal}
                />
                {this.state.progressBar && (
                    <div className="progressBar">
                        <ProgressBar height="2" bgcolor="#B14880" />
                    </div>
                )}
            </header>
        );
    }
}

Header.contextType = ContextProviderComponent;
export default Header;
