import React from "react";
import "./impressumComponent.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import { Link } from "gatsby";

import { isMobile, isTablet, isDesktop } from "react-device-detect";

class ImpressumComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title || [],
      text: this.props.slice.primary.text || []
    };
  }

  componentDidMount() {
    
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  getTitle = () => {
    if (this.state.title) {
      if (this.state.title instanceof Array) {
        this.state.title.map((title, i) => {
          return <h1 key={i}>{title.text}</h1>;
        });
      } else {
        return <h1>{this.state.title.text}</h1>;
      }
    }
    //return <h1></h1>;
  };

  getText = () => {
    if (this.state.text) {
      if (this.state.text instanceof Array) {
        this.state.text.map((text, i) => {
          return <p key={i}>{text.text}</p>;
        });
      } else {
        return <p>{this.state.text.text}</p>;
      }
    }
    //return <p></p>;
  };

  render() {
    
    return (
      <section id="imprintSection" className="imprintSection">
        <Observer
          threshold="0.0"
          onChange={(inView, entry) =>
            this.handleContentVisibility(inView, entry)
          }
          className="wrapper"
        >
          <div className="imprintSection__cont">
            {this.state.title &&
              this.state.title.map((title, i) => {
                return <h1 key={i}>{title.text}</h1>;
              })}
            {this.state.text &&
              this.state.text.map((text, i) => {
                return text.spans.length > 0 && text.spans[0].type == "strong" ? <strong key={i}>{text.text}</strong> : <p key={i}>{text.text}</p>;
              })}
          </div>
        </Observer>
      </section>
    );
  }
}
ImpressumComponent.contextType = ContextProviderComponent;
export default ImpressumComponent;
