import React from "react";
import "./blackFullPageSliderComponents.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import { ContextProviderComponent } from "../../contextProviderComponent";
import downButtonImg from "../../../images/privatkunden/down_button.svg";
import lineVectorImg from "../../../images/homepage/line_vector.svg";
import blueCheckmark from "../../../images/icons/circle-check-blue.svg";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";

class BlackFullPageSliderComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.description.text,
      ctaText: this.props.slice.primary.cta_text.text,
      sectionName: this.props.slice.primary.section_title.text,
      sectionNumber: this.props.slice.primary.section_number.text,
      items: this.props.slice.items,
      listItems: [
        this.props.slice.primary.item1?.text,
        this.props.slice.primary.item2?.text,
        this.props.slice.primary.item3?.text,
        this.props.slice.primary.item4?.text,
        this.props.slice.primary.item5?.text,
        this.props.slice.primary.item6?.text,
        this.props.slice.primary.item7?.text
      ],
      showScrollButton: false
    };
    this.itemsRefs = [this.props.slice.items.length];
    this.listItemsRefs = [7];
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    const elems = document.querySelectorAll(".blackFullPageSlider__cont_content");
    // Set starting params for sections
    gsap.set(elems, {
      y: 50,
      opacity: 0,
      duration: 1,
      ease: "power3.out",
      overwrite: "auto"
    });

    gsap.set(this.bottomLeft, {
      y: 50,
      opacity: 0
    });

    this.listItemsRefs.forEach((item) => {
      gsap.set(item, { opacity: 0, y: 50 });
    });
    setTimeout(() => {
      this.itemsRefs.forEach((item) => {
        ScrollTrigger.create({
          trigger: item,
          start: !isMobile ? "top 50%" : "top 75%",
          end: !isMobile ? "bottom 65%" : "bottom 70%",
          onEnter: () =>
            gsap.to(item, {
              y: 0,
              opacity: 1,
              stagger: 0.2
            }),
          onLeave: () =>
            gsap.to(item, {
              y: -50,
              opacity: 0,
              stagger: 0.2
            }),
          onEnterBack: () =>
            gsap.to(item, {
              y: 0,
              opacity: 1,
              stagger: -0.2
            }),
          onLeaveBack: () =>
            gsap.to(item, {
              y: 50,
              opacity: 0,
              stagger: -0.2
            })
        });
      });
    }, 300);

    ScrollTrigger.create({
      trigger: this.blackFullPageSlider,
      start: "top 10%",
      end: "bottom 95%",
      onEnter: () =>
        gsap.to(this.scrollButton, {
          opacity: 1,
          stagger: 0.2
        }),
      onLeave: () =>
        gsap.to(this.scrollButton, {
          opacity: 0,
          stagger: 0.2
        }),
      onEnterBack: () =>
        gsap.to(this.scrollButton, {
          opacity: 1,
          stagger: -0.2
        }),
      onLeaveBack: () =>
        gsap.to(this.scrollButton, {
          opacity: 0,
          stagger: -0.2
        })
    });
  }

  scrollToNextSection = () => {
    gsap.to(window, { duration: 1, scrollTo: "#advantages" });
  };

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.bottomLeft, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.2
      });

      var delay = 0.2;
      this.listItemsRefs.forEach((item) => {
        delay = delay + 0.1;
        TM.to(item, 0.5, {
          opacity: 1,
          y: 0,
          ease: Power3.easeOut,
          delay: delay
        });
      });
    }
  }

  render() {
    return (
      <section ref={(ref) => (this.blackFullPageSlider = ref)} id="blackFullPageSlider" className="blackFullPageSlider">
        <div className="blackFullPageSlider__cont">
          {this.state.items.map((el, i) => {
            return (
              <div key={"slider" + i} ref={(ref) => (this.itemsRefs[i] = ref)} className="blackFullPageSlider__cont_content">
                <h2 className="blackFullPageSlider__cont_content_title">{el.title.text}</h2>
                <p className="blackFullPageSlider__cont_content_subtitle">{el.sublabel.text}</p>
              </div>
            );
          })}
        </div>
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div ref={(ref) => (this.mainSlider = ref)} className="blackFullPageSlider__cont_bottomContainer">
            <div className="blackFullPageSlider__cont_bottomContainer_content">
              <div className="blackFullPageSlider__cont_bottomContainer_content_pageNumber">
                <span>{this.state.sectionNumber}</span>
              </div>
              <div className="blackFullPageSlider__cont_bottomContainer_content_left" ref={(ref) => (this.bottomLeft = ref)}>
                <span className="blackFullPageSlider__cont_bottomContainer_content_left_pageTitle">
                  {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                    <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                  ) : (
                    ""
                  )}
                  {this.state.sectionName}
                </span>
                <h2 ref={(ref) => (this.title = ref)} className="blackFullPageSlider__cont_bottomContainer_content_left_title">
                  {this.state.title}
                </h2>
                <h3
                  ref={(ref) => (this.subtitle = ref)}
                  className="blackFullPageSlider__cont_bottomContainer_content_left_subtitle">
                  {this.state.subtitle}
                </h3>
                <div
                  className="button button-hoverBlue blackFullPageSlider__cont_bottomContainer_content_left_button"
                  onClick={() => this.context.navigateClickCallback(4)}>
                  <p>{this.state.ctaText}</p>
                </div>
              </div>
              <div className="blackFullPageSlider__cont_bottomContainer_content_right">
                {this.state.listItems.map((item, i) => {
                  return (
                    <div
                      ref={(ref) => (this.listItemsRefs[i] = ref)}
                      className="blackFullPageSlider__cont_bottomContainer_content_right_item">
                      <img alt="blue checkmark" src={blueCheckmark} />
                      <p>{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Observer>
        <img
          ref={(ref) => (this.scrollButton = ref)}
          src={downButtonImg}
          alt="download icon"
          className="blackFullPageSlider_scrollDownImg"
          onClick={this.scrollToNextSection}
        />
      </section>
    );
  }
}
BlackFullPageSliderComponents.contextType = ContextProviderComponent;
export default BlackFullPageSliderComponents;
