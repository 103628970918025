import React from 'react';
import './testimonialSmallComponent.scss';
import Observer from 'react-intersection-observer';

import {TweenMax as TM, Power2, Power3} from 'gsap/all';
import {ContextProviderComponent} from '../../contextProviderComponent';

import image from '../../../images/homepage/testimonialSmall.jpg';

import {isMobile, isTablet, isDesktop, isMobileOnly} from 'react-device-detect';

class TestimonialSmallComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionNumber: this.props.slice.primary.section_number.text,
            sectionName: this.props.slice.primary.section_name.text,
            image: this.props.slice.primary.image.url ? this.props.slice.primary.image.url : image,
            title: this.props.slice.primary.title.text,
            text: this.props.slice.primary.text.text,
            reversed: this.props.slice.primary.reversed,
            button: this.props.slice.primary.isbutton,
            buttonText: this.props.slice.primary.buttontext.text,
            buttonLink: this.props.slice.primary.button_link ? this.props.slice.primary.button_link.url : '',
            personName: this.props.slice.primary.person_name ? this.props.slice.primary.person_name.text : null,
            personPosition: this.props.slice.primary.person_position ? this.props.slice.primary.person_position.text : null,
        };
    }

    componentDidMount() {}

    handleContentVisibility(inView, entry) {
        if (inView) {
            TM.to(entry.target.firstChild, 0.8, {
                opacity: 1,
                ease: Power2.easeInOut,
                delay: 0.0,
            });
        }
    }

    render() {
        return (
            <section id="testimonialSmall" className="testimonialSmall">
                <Observer
                    threshold="0.5"
                    onChange={(inView, entry) => this.handleContentVisibility(inView, entry)}
                    className="wrapper"
                >
                    {((isDesktop && !isTablet) || (isTablet && window.innerWidth > window.innerHeight)) && (
                        <div
                            className="testimonialSmall__cont"
                            style={{flexDirection: this.state.reversed ? 'row-reverse' : 'row'}}
                        >
                            <div className="testimonialSmall__cont_left">
                                <div
                                    className="testimonialSmall__cont_left__pageNumber"
                                    style={{display: this.state.reversed ? 'none' : 'flex'}}
                                >
                                    <span>{this.state.sectionNumber}</span>
                                </div>
                                <div
                                    className="testimonialSmall__cont_left__text"
                                    style={{
                                        marginLeft: this.state.reversed ? '135px' : '90px',
                                        alignSelf: this.state.reversed ? 'center' : 'unset',
                                    }}
                                >
                                    <span style={{marginRight: this.state.reversed ? '30px' : '0px'}}>
                                        {this.state.reversed ? this.state.sectionNumber : ''}
                                    </span>
                                    <span>{this.state.sectionName}</span>
                                    <h2>{this.state.title}</h2>
                                    <p>{this.state.text}</p>
                                </div>
                            </div>
                            <div className="testimonialSmall__cont_right">
                                <img src={this.state.image} alt="smartwealth testimonial image"></img>
                                {this.state.personName && this.state.personPosition && (
                                    <div className="personinfo">
                                        {' '}
                                        <p>{this.state.personName}</p>{' '}
                                        <p>
                                            &nbsp;<span className="dark">•</span>&nbsp;{this.state.personPosition}
                                        </p>{' '}
                                    </div>
                                )}
                                {this.state.button && (
                                    <div
                                        className="button button-hoverBlue"
                                        onClick={() => this.context.callBackForCalendlyModalShow('flex')}
                                    >
                                        <p>{this.state.buttonText}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {(isMobileOnly || (isTablet && window.innerWidth < window.innerHeight)) && (
                        <div className="testimonialSmall__cont">
                            <div className="testimonialSmall__cont_left">
                                <div className="testimonialSmall__cont_left__text">
                                    <span style={{marginRight: '24px'}}>{this.state.sectionNumber}</span>
                                    <span>{this.state.sectionName}</span>
                                    <h2>{this.state.title}</h2>
                                    <p>{this.state.text}</p>
                                </div>
                            </div>
                            <div className="testimonialSmall__cont_right">
                                <img src={this.state.image} alt="smartwealth testimonial image"></img>
                                {this.state.personName && this.state.personPosition && (
                                    <div className="personinfo">
                                        {' '}
                                        <p>{this.state.personName}</p>{' '}
                                        <p>
                                            &nbsp;<span className="dark">•</span>&nbsp;{this.state.personPosition}
                                        </p>{' '}
                                    </div>
                                )}
                                {this.state.button && (
                                    <div
                                        className="button button-hoverBlue"
                                        onClick={() =>
                                            this.state.buttonLink != ''
                                                ? window.open(this.state.buttonLink, '_blank')
                                                : this.context.callBackForCalendlyModalShow('flex')
                                        }
                                    >
                                        <p>{this.state.buttonText}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Observer>
            </section>
        );
    }
}
TestimonialSmallComponent.contextType = ContextProviderComponent;
export default TestimonialSmallComponent;
