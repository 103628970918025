import React from "react";
import "./blueComponent.scss";
import Observer from "react-intersection-observer";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";
import whiteCheckMark from "../../../images/icons/circle-check-white.svg";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

class BlueComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text,
      sectionName: this.props.slice.primary.section_title.text,
      title: this.props.slice.primary.title.text,
      title_subtext: this.props.slice.primary.title_subtext.text,
      subtitle: this.props.slice.primary.subtitle.text,
      subtitle_subtext: this.props.slice.primary.subtitle_subtext.text
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set([this.title, this.subtitle, this.list, this.image], { opacity: 0, y: 50 });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.title_subtext, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(this.subtitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.6
      });
      TM.to(this.subtitle_subtext, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.7
      });
    }
  }

  render() {
    return (
      <section id="blueComponent" className="blueComponent">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="blueComponent__cont">
            <div className="blueComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="blueComponent__cont_contentWrapper">
              <div className="blueComponent__cont_contentWrapper_content">
                <span className="blueComponent__cont_contentWrapper_content_pageTitle">
                  {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                    <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                  ) : (
                    ""
                  )}
                  {this.state.sectionName}
                </span>
                <div className="blueComponent__cont_contentWrapper_content_row">
                  <h2
                    ref={(ref) => {
                      this.title = ref;
                    }}
                    className="blueComponent__cont_contentWrapper_content_title">
                    {this.state.title}
                  </h2>
                  <p
                    ref={(ref) => {
                      this.title_subtext = ref;
                    }}
                    className="blueComponent__cont_contentWrapper_content_title_subtext">
                    {this.state.title_subtext}
                  </p>
                </div>
                <div className="blueComponent__cont_contentWrapper_content_delimiter"></div>
                <div className="blueComponent__cont_contentWrapper_content_column">
                  <h3
                    ref={(ref) => {
                      this.subtitle = ref;
                    }}
                    className="blueComponent__cont_contentWrapper_content_subtitle">
                    {this.state.subtitle}
                  </h3>
                  <p
                    ref={(ref) => {
                      this.subtitle_subtext = ref;
                    }}
                    className="blueComponent__cont_contentWrapper_content_subtitle_subtext">
                    {this.state.subtitle_subtext}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}

BlueComponent.contextType = ContextProviderComponent;
export default BlueComponent;
