import React from 'react';
import './yourAssetsComponent.scss';
import Observer from 'react-intersection-observer';

import {TweenMax as TM, Power1, Power2, Power3, gsap} from 'gsap/all';
import {ContextProviderComponent} from '../../contextProviderComponent';

class YourAssetsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.slice.primary.title.text,
            subtitle: this.props.slice.primary.subtitle.text,
            button_text: this.props.slice.primary.button_text.text,
            buttonLink: this.props.slice.primary.button_link ? this.props.slice.primary.button_link.url : '',
        };
    }

    componentDidMount() {
        this.setState({index: 0});
        gsap.set([this.title, this.subtitle, this.button], {opacity: 0, y: 50});
    }

    handleContentVisibility(inView, entry) {
        if (inView) {
            TM.to(this.title, 0.5, {
                opacity: 1,
                y: 0,
                ease: Power3.easeOut,
                delay: 0.2,
            });
            TM.to(this.subtitle, 0.5, {
                opacity: 1,
                y: 0,
                ease: Power3.easeOut,
                delay: 0.3,
            });
            TM.to(this.button, 0.5, {
                opacity: 1,
                y: 0,
                ease: Power3.easeOut,
                delay: 0.4,
            });
        }
    }

    render() {
        return (
            <section id="yourAssets" className="yourAssets">
                <Observer
                    threshold="0.1"
                    onChange={(inView, entry) => this.handleContentVisibility(inView, entry)}
                    className="wrapper"
                >
                    <div className="yourAssets__cont">
                        <h2
                            ref={ref => {
                                this.title = ref;
                            }}
                            className="yourAssets__cont_title"
                        >
                            {this.state.title}
                        </h2>
                        <h3
                            ref={ref => {
                                this.subtitle = ref;
                            }}
                            className="yourAssets__cont_subtitle"
                        >
                            {this.state.subtitle}
                        </h3>
                        <div
                            ref={ref => {
                                this.button = ref;
                            }}
                            id="heroTextBtn"
                            className="hero__cont_button button yourAssets__cont_button"
                            onClick={() =>
                                this.state.buttonLink != ''
                                    ? window.open(this.state.buttonLink, '_blank')
                                    : this.context.navigateClickCallback(4)
                            }
                        >
                            {this.state.button_text && <p>{this.state.button_text}</p>}
                        </div>
                    </div>
                </Observer>
            </section>
        );
    }
}
YourAssetsComponent.contextType = ContextProviderComponent;
export default YourAssetsComponent;
