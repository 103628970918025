import React from "react";
import "./textureComponent.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import ellipseImg from "../../../images/homepage/ellipse.png";

class TextureComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title.text,
      buttonText: this.props.slice.primary.buttontext.text,
      emailText: this.props.slice.primary.emailtext.text
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="texture" className="texture">
        <Observer threshold="0.2" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="texture__cont">
            <div className="texture__cont_left">
              <h2>{this.state.title}</h2>
            </div>
            <div className="texture__cont_right">
              <div id="textureBtn" className="button" onClick={() => this.context.callBackForCalendlyModalShow("flex")}>
                <span>{this.state.buttonText}</span>
              </div>
              <p>
                {this.state.emailText}&nbsp;<a href="mailto:support@smartwealth.ch">support@smartwealth.ch</a>
              </p>
            </div>
            <img src={ellipseImg} alt="smartwealth ellipse"></img>
          </div>
        </Observer>
      </section>
    );
  }
}
TextureComponent.contextType = ContextProviderComponent;
export default TextureComponent;
