import React from "react";
import { Provider } from "react-redux";

import "./contactComponent.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import store from "./store";
import { submitForm } from "./utils.js";
import ContactForm from "../../contactFormComponent/ContactForm";

import { Link } from "gatsby";

import { isBrowser, isMobileOnly, isMobile, isTablet, isIPad13, withOrientationChange } from "react-device-detect";

import contactSectionImg from "../../../images/contactus/contactSection.jpg";
import smallTriangelImg from "../../../images/icons/small_triangel_black.svg";
import texture2Img from "../../../images/homepage/texture2.png";
import ellipseImg from "../../../images/contactus/ellipse_circle.png";

class ContactComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      sectionTitle: this.props.slice.primary.section_title.text || "",
      sectionDescription: this.props.slice.primary.section_description.raw || [],
      textButton: this.props.slice.primary.viewonmap.text || "",
      addressTitle: this.props.slice.primary.address_title.text || "",
      address: this.props.slice.primary.address.raw || [],
      openhoursTitle: this.props.slice.primary.openhours_title.text || "",
      openhours: this.props.slice.primary.openhours.raw || [],
      contactTitle: this.props.slice.primary.contact_title.text || "",
      contact: this.props.slice.primary.contact.raw || [],
      thankYou: this.props.slice.primary.thank_you.raw || []
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="contactSection" className="contactSection">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="contactSection__cont">
            <div className="contactSection__cont_left">
              <img alt="smartwealth icon" src={texture2Img} alt=""></img>
              <div className="contactSection__cont_left__pageNumber">
                <span>{this.state.sectionNumber}</span>
              </div>
              <div className="contactSection__cont_left__text">
                <span>
                  {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                    <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                  ) : (
                    ""
                  )}
                  {this.state.sectionName}
                </span>
                {this.state.sectionTitle && <h2>{this.state.sectionTitle}</h2>}
                {this.state.sectionDescription.map((paragraph, i) => {
                  return <p key={i}>{paragraph.text}</p>;
                })}

                {(isMobileOnly ||
                  (isTablet && window.innerHeight > window.innerWidth) ||
                  (isTablet && window.innerHeight <= window.innerWidth && window.innerWidth <= 1024)) && (
                  <div className="contact__form">
                    <Provider store={store}>
                      <ContactForm onSubmit={submitForm} data={this.props.slice.primary} />
                    </Provider>
                    <div className="thank__you">
                      {this.state.thankYou.map((paragraph, i) => {
                        if (i == 0) {
                          return <h2 key={i}>{paragraph.text}</h2>;
                        } else {
                          return <p key={i}>{paragraph.text}</p>;
                        }
                      })}
                    </div>
                  </div>
                )}

                <div className="contactSection__cont_left__text__columns">
                  <div className="contactSection__cont_left__text__columns_column">
                    <h3>{this.state.addressTitle}</h3>
                    {this.state.address.map((paragraph, i) => {
                      return <p key={i}>{paragraph.text}</p>;
                    })}
                  </div>
                  <div className="contactSection__cont_left__text__columns_column">
                    <h3>{this.state.openhoursTitle}</h3>
                    {this.state.openhours.map((paragraph, i) => {
                      return <p key={i}>{paragraph.text}</p>;
                    })}
                  </div>
                  <div className="contactSection__cont_left__text__columns_column">
                    <h3>{this.state.contactTitle}</h3>
                    {this.state.contact.map((paragraph, i) => {
                      if (i == 0) {
                        return (
                          <a href="tel:+41001112233">
                            <p key={i}>{paragraph.text}</p>
                          </a>
                        );
                      } else if (i == 1) {
                        return (
                          <a href="mailto:support@smartwealth.ch">
                            <p key={i} className="blue">
                              {paragraph.text}
                            </p>
                          </a>
                        );
                      } else {
                        return <p key={i}>{paragraph.text}</p>;
                      }
                    })}
                  </div>
                </div>

                {this.state.textButton && (
                  <Link
                    target="_blank"
                    to="https://www.google.com/maps/place/Smart+Wealth+AG/@47.3597454,8.5466139,17z/data=!3m1!4b1!4m5!3m4!1s0x479aa751bf03e1eb:0xe9773a05f1d32421!8m2!3d47.3597427!4d8.5488137"
                    className="textbutton">
                    <p>
                      {this.state.textButton}
                      <img alt="smartwealth triangle icon" src={smallTriangelImg}></img>
                    </p>
                  </Link>
                )}
              </div>
            </div>
            <div className="contactSection__cont_right">
              <div className="contactSection__cont_right__form contact__form">
                <Provider store={store}>
                  <ContactForm onSubmit={submitForm} data={this.props.slice.primary} />
                </Provider>
                <div className="thank__you">
                  {this.state.thankYou.map((paragraph, i) => {
                    if (i == 0) {
                      return <h2 key={i}>{paragraph.text}</h2>;
                    } else {
                      return <p key={i}>{paragraph.text}</p>;
                    }
                  })}
                </div>
              </div>

              <img alt="smartwealth ellipse icon" src={ellipseImg} alt=""></img>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
ContactComponent.contextType = ContextProviderComponent;
export default withOrientationChange(ContactComponent);
