import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import validate from './validate';

const renderTextField = (
  { input, label, placeholder, helperText, meta: { touched, error }, ...custom },
) => (
  <TextField
    placeholder={placeholder}
    helperText={error}
    label={label}
    error={touched && !!error}
    {...input}
    {...custom}
  />
);

const renderTextArea = (
  { input, label, placeholder, minRows, maxRows, ...custom },
) => (
  <TextField
    placeholder={placeholder}
    label={label}
    multiline={true}
    minRows={minRows}
    maxRows={maxRows}
    fullWidth={true}
    {...input}
    {...custom}
  />
);

const renderCheckbox = ({ input, label }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={input.value ? true : false}
        onChange={input.onChange}
      />
    }
    label={label}/>
);

const ContactForm = props => {

  const { handleSubmit, pristine, submitting } = props;

  return (
    <form onSubmit={handleSubmit}>

      <div>
        <Field
          name="name"
          component={renderTextField}
          label={props.data.name.text}
          placeholder={props.data.name_placeholder.text}
        />
      </div>

      <div>
        <Field name="email" component={renderTextField} label={props.data.email.text} placeholder={props.data.email_placeholder.text}/>
      </div>

      <div>
        <Field name="phone" component={renderTextField} label={props.data.phone.text} placeholder={props.data.phone_placeholder.text}/>
      </div>

      <div>
        <Field
          name="message"
          component={renderTextArea}
          label={props.data.message.text}
          minRows="3"
          maxRows="6"
          placeholder={props.data.message_placeholder.text}
        />
      </div>

      <div>
        <Field name="callMe" component={renderCheckbox} label={props.data.bettercallme.text} />
      </div>

      <div>
        <button className={(pristine || submitting) ? "button buttonDisabled" : "button"} type="submit" disabled={pristine || submitting}><span>{props.data.button_text.text}</span></button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'ContactForm', // a unique identifier for this form
  validate,
})(ContactForm);
