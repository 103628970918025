import React from "react";
//import { trackCustomEvent } from "gatsby-plugin-google-analytics"
//import ReactGA from 'react-ga';
import CSSPlugin from "gsap/CSSPlugin";
import { TweenMax, Power0 } from "gsap/all";

import { isBrowser } from "react-device-detect";

const C = CSSPlugin;

class CalendlyComponent extends React.Component {
  calendlyScriptSrc = "https://assets.calendly.com/assets/external/widget.js";

  buildCalendlyUrl = (account, eventName) =>
    `https://calendly.com/${account}/${eventName}?background_color=17181D&text_color=FFFFFF&primary_color=214FB7`;

  componentDidMount() {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute("src", this.calendlyScriptSrc);
    script.setAttribute("id", "calendlyScript");
    head.appendChild(script);

    function isCalendlyEvent(e) {
      return e.data.event && e.data.event.indexOf("calendly") === 0 && e.data.event === "calendly.event_scheduled";
    }

    /*window.addEventListener(
      'message',
      function(e) {
        if (isCalendlyEvent(e)) {
          ReactGA.pageview(window.location.pathname+"calendlyModal/submit");
          trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Scheduled calendly meeting",
            // string - required - Type of interaction (e.g. 'play')
            action: "Click",
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: "Exp Redesign - Calendly meeting succesfully scheduled",
            // number - optional - Numeric value associated with the event. (e.g. A product ID)
            value: 1
          })

          // setTimeout(() => {

          //   document.querySelector("html").classList.remove("stop-scrolling");
            
          //   TweenMax.to(document.querySelectorAll('#schedule_form')[0], 0.25, {
          //     opacity: 0,
          //     ease: Power0.easeNone,
          //     onComplete: function() {
          //       document.querySelectorAll('#schedule_form')[0].style.visibility = 'hidden';
        
          //       TweenMax.to("#modalEmbeeded", 0.3, {
          //         opacity: 0,
          //         ease: Power0.easeNone,
          //         delay: 0.2,
          //         onComplete: function() {
          //           TweenMax.to("#modalEmbeeded", 0.1, {
          //             scale: 0,
          //             ease: Power0.easeNone,
          //             onComplete: function() {
          //               document.querySelectorAll('#modalEmbeeded')[0].style.display = 'none';
          //             }
          //           })
          //         }
          //       })
          //     }
          //   })

          // }, 3000)
          
        }
      }
    );*/
  }

  componentWillUnmount() {
    const head = document.querySelector("head");
    const script = document.getElementById("calendlyScript");
    if (script) {
      head.removeChild(script);
      console.log("script deleted", script);
    }
  }

  render() {
    const { account, eventName } = this.props;

    return (
      <div id="schedule_form">
        <div
          className="calendly-inline-widget"
          data-url={this.buildCalendlyUrl(account, eventName)}
          style={{
            minWidth: "100vw",
            height: isBrowser ? "100vh" : "85vh",
            position: "absolute",
            top: "calc(20vh - 132px)",
            left: "0",
            opacity: 0
          }}
        />
      </div>
    );
  }
}

export default CalendlyComponent;
