import React from "react";
import "./investmentSliderComponent.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import prevIcon from "../../../images/icons/prev.svg";
import nextIcon from "../../../images/icons/next.svg";
import $ from "jquery";

class investmentSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.subtitle.text,
      backgroundText: this.props.slice.primary.background_text.text,
      slides: this.props.slice.items,
      index: 0,
      wait: false
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set([this.title, this.subtitle], { opacity: 0, y: 50 });
  }

  slidePrevious = () => {
    this.setState({ wait: true });
    let newIndex = this.state.index - 1;

    if (newIndex < 0) {
      newIndex += this.state.slides.length;
    }

    TM.to(this.slide, 0.3, {
      x: "20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to(this.slide, 0.05, {
          x: "-20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(this.slide, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3,
              onComplete: function() {
                this.setState({ wait: false });
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
  };

  slideNext = () => {
    this.setState({ wait: true });
    let newIndex = (this.state.index + 1) % this.state.slides.length;

    TM.to(this.slide, 0.3, {
      x: "-20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to(this.slide, 0.05, {
          x: "20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(this.slide, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3,
              onComplete: function() {
                this.setState({ wait: false });
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
  };

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0
      });
      TM.to(this.subtitle, 0.5, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.2
      });
    }
  }

  render() {
    return (
      <section id="investmentSlider" className="investmentSlider">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="investmentSlider__cont">
            <h2
              ref={(ref) => {
                this.title = ref;
              }}
              className="investmentSlider__cont_title">
              {this.state.title}
            </h2>
            <h3
              ref={(ref) => {
                this.subtitle = ref;
              }}
              className="investmentSlider__cont_subtitle">
              {this.state.subtitle}
            </h3>
            <div className="investmentSlider__cont_sliderCont">
              <div
                ref={(ref) => {
                  this.slide = ref;
                }}
                className="investmentSlider__cont_sliderCont_slide">
                <div className="investmentSlider__cont_sliderCont_slide_pageNumber">
                  <span>{this.state.slides[this.state.index].label.text}</span>
                </div>
                <div className="investmentSlider__cont_sliderCont_slide_text">
                  <p>{this.state.slides[this.state.index].text.text}</p>
                </div>
              </div>
              <div className="investmentSlider__cont_sliderCont_controls">
                <img
                  className="investmentSlider__cont_sliderCont_controls--prev"
                  alt="smartwealth previous arrov icon"
                  src={prevIcon}
                  onClick={this.slidePrevious}></img>
                <img
                  alt="smartwealth next arrov icon"
                  className="investmentSlider__cont_sliderCont_controls--next"
                  src={nextIcon}
                  onClick={this.slideNext}></img>
              </div>
            </div>
          </div>
        </Observer>
        <h3 className="investmentSlider__backroundText">{this.state.backgroundText}</h3>
      </section>
    );
  }
}
investmentSliderComponent.contextType = ContextProviderComponent;
export default investmentSliderComponent;
