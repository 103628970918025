import React from "react";
import "./outstandingResultsSliderComponent.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap";
import { ContextProviderComponent } from "../../contextProviderComponent";
import downButtonImg from "../../../images/privatkunden/down_button.svg";
import lineVectorImg from "../../../images/homepage/line_vector.svg";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

class OutstandingResultsSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.subtitle.text,
      showScrollButton: false
    };
    this.itemsRefs = [this.props.slice.items.length];
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    const elems = document.querySelectorAll(".outstandingResults__cont_content");
    // Set starting params for sections
    gsap.set(elems, {
      y: 50,
      opacity: 0,
      duration: 1,
      ease: "power3.out",
      overwrite: "auto"
    });

    ScrollTrigger.create({
      trigger: this.mainSlider,
      start: "top 50%",
      end: "bottom 65%",
      onEnter: () =>
        gsap.to(this.mainSlider, {
          y: 0,
          opacity: 1,
          stagger: 0.2
        }),
      onLeave: () =>
        gsap.to(this.mainSlider, {
          y: -50,
          opacity: 0,
          stagger: 0.2
        }),
      onEnterBack: () =>
        gsap.to(this.mainSlider, {
          y: 0,
          opacity: 1,
          stagger: -0.2
        }),
      onLeaveBack: () =>
        gsap.to(this.mainSlider, {
          y: 50,
          opacity: 0,
          stagger: -0.2
        })
    });

    this.itemsRefs.forEach((item) => {
      ScrollTrigger.create({
        trigger: item,
        start: "top 50%",
        end: "bottom 65%",
        onEnter: () =>
          gsap.to(item, {
            y: 0,
            opacity: 1,
            stagger: 0.2
          }),
        onLeave: () =>
          gsap.to(item, {
            y: -50,
            opacity: 0,
            stagger: 0.2
          }),
        onEnterBack: () =>
          gsap.to(item, {
            y: 0,
            opacity: 1,
            stagger: -0.2
          }),
        onLeaveBack: () =>
          gsap.to(item, {
            y: 50,
            opacity: 0,
            stagger: -0.2
          })
      });
    });

    ScrollTrigger.create({
      trigger: this.outstandingResults,
      start: "top 10%",
      end: "bottom 95%",
      onEnter: () =>
        gsap.to(this.scrollButton, {
          opacity: 1,
          stagger: 0.2
        }),
      onLeave: () =>
        gsap.to(this.scrollButton, {
          opacity: 0,
          stagger: 0.2
        }),
      onEnterBack: () =>
        gsap.to(this.scrollButton, {
          opacity: 1,
          stagger: -0.2
        }),
      onLeaveBack: () =>
        gsap.to(this.scrollButton, {
          opacity: 0,
          stagger: -0.2
        })
    });
  }

  scrollToNextSection = () => {
    gsap.to(window, { duration: 1, scrollTo: "#advantages" });
  };

  render() {
    return (
      <section ref={(ref) => (this.outstandingResults = ref)} id="outstandingResults" className="outstandingResults">
        <div className="outstandingResults__cont">
          <div ref={(ref) => (this.mainSlider = ref)} className="outstandingResults__cont_content">
            <h2 ref={(ref) => (this.title = ref)} className="outstandingResults__cont_content_title">
              {this.state.title}
            </h2>
            <h3 ref={(ref) => (this.subtitle = ref)} className="outstandingResults__cont_content_subtitle">
              {this.state.subtitle}
            </h3>
          </div>
          {this.props.slice.items.map((el, i) => {
            return (
              <div key={"slider" + i} ref={(ref) => (this.itemsRefs[i] = ref)} className="outstandingResults__cont_content">
                <h2 className="outstandingResults__cont_content_title">{el.slider_item.text}</h2>
              </div>
            );
          })}
        </div>
        <img
          ref={(ref) => (this.scrollButton = ref)}
          src={downButtonImg}
          alt="smartwealth scroll down icon"
          className="outstandingResults_scrollDownImg"
          onClick={this.scrollToNextSection}
        />
      </section>
    );
  }
}
OutstandingResultsSliderComponent.contextType = ContextProviderComponent;
export default OutstandingResultsSliderComponent;
