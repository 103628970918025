import React from "react";
import $ from "jquery";
import Observer from "react-intersection-observer";
import "./performanceComponent.scss";
import { Chart, Bar } from "react-chartjs-2";
import disableScroll from "disable-scroll";
import { TweenMax as TM, Power1, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import performanceBackground from "../../../images/homepage/performance_background.jpg";
import performanceTexture from "../../../images/homepage/performance_texture.png";
import closeImg from "../../../images/icons/close.svg";
import staticChartDE from "./static_chart.png";
import staticChartEN from "./static_chart_en.png";
import { isBrowser, isTablet, isDesktop } from "react-device-detect";

var barChart = null;

var tooltipData = {
  portfolio: [
    {
      title: "SW Ziel Rendite 4%",
      performance: "6.43%",
      perf_risk: "1.63",
      drawdown: "-12.03%",
      allocation: "20/75/5"
    },
    {
      title: "SW Ziel Rendite 6%",
      performance: "7.24%",
      perf_risk: "1.71",
      drawdown: "-11.33%",
      allocation: "25/70/5"
    },
    {
      title: "SW Ziel Rendite 8%",
      performance: "8.60%",
      perf_risk: "1.70",
      drawdown: "-12.21%",
      allocation: "30/65/5"
    },
    {
      title: "SW Ziel Rendite 10%",
      performance: "10.52%",
      perf_risk: "1.69",
      drawdown: "-13.57%",
      allocation: "35/60/5"
    },
    {
      title: "SW Ziel Rendite 12%",
      performance: "12.83%",
      perf_risk: "1.38",
      drawdown: "-17.63%",
      allocation: "60/35/5"
    },
    {
      title: "SW Ziel Rendite 14%",
      performance: "14.28%",
      perf_risk: "1.32",
      drawdown: "-19.57%",
      allocation: "70/25/5"
    },
    {
      title: "SW Ziel Rendite 16%",
      performance: "15.39%",
      perf_risk: "1.26",
      drawdown: "-22.49%",
      allocation: "75/20/5"
    },
    {
      title: "SW Ziel Rendite 18%",
      performance: "16.72%",
      perf_risk: "1.22",
      drawdown: "-25.11%",
      allocation: "85/10/5"
    },
    {
      title: "SW Ziel Rendite 20%",
      performance: "18.24%",
      perf_risk: "1.23",
      drawdown: "-27.43%",
      allocation: "90/5/5"
    }
  ],
  benchmark: [
    {
      title: "SW Ziel Rendite 4%",
      performance: "4.60%",
      perf_risk: "0.81",
      drawdown: "-20.18%",
      allocation: "20/75/5"
    },
    {
      title: "SW Ziel Rendite 6%",
      performance: "4.83%",
      perf_risk: "0.80",
      drawdown: "-22.92%",
      allocation: "25/70/5"
    },
    {
      title: "SW Ziel Rendite 8%",
      performance: "5.06%",
      perf_risk: "0.78",
      drawdown: "-25.58%",
      allocation: "30/65/5"
    },
    {
      title: "SW Ziel Rendite 10%",
      performance: "5.28%",
      perf_risk: "0.75",
      drawdown: "-28.19%",
      allocation: "35/60/5"
    },
    {
      title: "SW Ziel Rendite 12%",
      performance: "6.28%",
      perf_risk: "0.60",
      drawdown: "-40.56%",
      allocation: "60/35/5"
    },
    {
      title: "SW Ziel Rendite 14%",
      performance: "6.64%",
      perf_risk: "0.55",
      drawdown: "-45.66%",
      allocation: "70/25/5"
    },
    {
      title: "SW Ziel Rendite 16%",
      performance: "6.81%",
      perf_risk: "0.53",
      drawdown: "-48.09%",
      allocation: "75/20/5"
    },
    {
      title: "SW Ziel Rendite 18%",
      performance: "7.12%",
      perf_risk: "0.49",
      drawdown: "-52.70%",
      allocation: "85/10/5"
    },
    {
      title: "SW Ziel Rendite 20%",
      performance: "7.27%",
      perf_risk: "0.48",
      drawdown: "-54.88%",
      allocation: "90/5/5"
    }
  ]
};

class PerformanceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChartNo: 1,
      sectionNumber: this.props.slice.primary.section_number.text || "",
      background: this.props.slice.primary.background.url
        ? this.props.slice.primary.background.url.split("?")[0]
        : performanceBackground,
      title1: this.props.slice.primary.title1.text || "",
      title2: this.props.slice.primary.title2.text || "",
      text1: this.props.slice.primary.text1.text || "",
      text2: this.props.slice.primary.text2.text || "",
      chartTitle1: this.props.slice.primary.chart1_title.text || "",
      chartTitle2: this.props.slice.primary.chart2_title.text || "",
      isCreatedOnce: false,
      data: {
        "de-ch": {
          labels: ["P06", "P08", "P10", "P12", "P14", "P16", "P18", "P20", "P22"],
          datasets: [
            {
              label: "BENCHMARKS",
              backgroundColor: "rgba(177,72,128,1)",
              borderColor: "rgba(31,34,40,0)",
              borderWidth: 4,
              hoverBackgroundColor: "rgba(177,72,128,1)",
              hoverBorderColor: "rgba(31,34,40,1)",
              data: [115.65, 124.09, 132.6, 141.17, 160.08, 156.81, 173.28, 189.51, 205.33],
              barThickness: 28
            },
            {
              label: "PORTFOLIOS",
              backgroundColor: "rgba(33,79,183,1)",
              borderColor: "rgba(31,34,40,0)",
              borderWidth: 4,
              hoverBackgroundColor: "rgba(33,79,183,1)",
              hoverBorderColor: "rgba(31,34,40,1)",
              data: [218.25, 293.12, 440.19, 548.31, 702.01, 904.75, 1085.56, 1345.73, 1698.09],
              barThickness: 28
            }
          ]
        },
        "en-gb": {
          labels: ["P06", "P08", "P10", "P12", "P14", "P16", "P18", "P20", "P22"],
          datasets: [
            {
              label: "BENCHMARKS",
              backgroundColor: "rgba(177,72,128,1)",
              borderColor: "rgba(31,34,40,0)",
              borderWidth: 4,
              hoverBackgroundColor: "rgba(177,72,128,1)",
              hoverBorderColor: "rgba(31,34,40,1)",
              data: [115.65, 124.09, 132.6, 141.17, 160.08, 156.81, 173.28, 189.51, 205.33],
              barThickness: 28
            },
            {
              label: "PORTFOLIOS",
              backgroundColor: "rgba(33,79,183,1)",
              borderColor: "rgba(31,34,40,0)",
              borderWidth: 4,
              hoverBackgroundColor: "rgba(33,79,183,1)",
              hoverBorderColor: "rgba(31,34,40,1)",
              data: [218.25, 293.12, 440.19, 548.31, 702.01, 904.75, 1085.56, 1345.73, 1698.09],
              barThickness: 28
            }
          ]
        }
      },
      options: {
        "de-ch": {
          type: "bar",
          maintainAspectRatio: false,
          responsive: true,
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                }
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                  return value + "%";
                }
              },
              grid: {
                color: "rgba(31,34,40,1)",
                lineWidth: 2,
                drawBorder: false
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: "top",
              align: "end",
              labels: {
                boxWidth: 12,
                boxHeight: 12,
                font: {
                  family: "Avenir",
                  color: "#717579",
                  size: 12
                },
                padding: 16
              }
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              external: function(context) {
                // Tooltip Element
                var tooltipEl = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  tooltipEl.innerHTML = "<table></table>";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var titleLines = tooltipModel.title || [];
                  //var bodyLines = tooltipModel.body.map(getBody);

                  var bodyLinesPortfolio = [];
                  var bodyLinesBenchmark = [];
                  var bodyLinesAllocation = [];

                  bodyLinesPortfolio.push({
                    title: "Ertrag p.a.",
                    text: tooltipData.portfolio[context.tooltip.dataPoints[0].dataIndex].performance
                  });
                  bodyLinesPortfolio.push({
                    title: "Ertrag/Risiko",
                    text: tooltipData.portfolio[context.tooltip.dataPoints[0].dataIndex].perf_risk
                  });
                  bodyLinesPortfolio.push({
                    title: "Max. Verlust",
                    text: tooltipData.portfolio[context.tooltip.dataPoints[0].dataIndex].drawdown
                  });

                  bodyLinesBenchmark.push({
                    title: "Ertrag p.a.",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].performance
                  });
                  bodyLinesBenchmark.push({
                    title: "Ertrag/Risiko",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].perf_risk
                  });
                  bodyLinesBenchmark.push({
                    title: "Max. Verlust",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].drawdown
                  });

                  bodyLinesAllocation.push({
                    title: "Aktien",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].allocation.split("/")[0]
                  });
                  bodyLinesAllocation.push({
                    title: "Bonds",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].allocation.split("/")[1]
                  });
                  bodyLinesAllocation.push({
                    title: "Rohstoffe",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].allocation.split("/")[2]
                  });

                  var innerHtml = "<thead>";

                  titleLines.forEach(function(title) {
                    innerHtml +=
                      '<tr style="text-transform: uppercase; letter-spacing: 0.04em; color:#17181D; text-align:left"><th colspan="2">' +
                      tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].title +
                      "</th></tr>";
                  });

                  innerHtml += '<tr style="letter-spacing: 0.04em; color:#17181D;"><th colspan="2">' + "" + "</th></tr>";

                  innerHtml += "</thead><tbody>";

                  innerHtml += '<tr><td colspan="2" style="width: 100%; height: 8px; border-top: 1px solid #17181D"></td></tr>';

                  bodyLinesPortfolio.forEach(function(body, i) {
                    innerHtml +=
                      '<tr><td style="letter-spacing: 0.04em; color:#717579; margin-right:30px;">' +
                      body.title +
                      '</td><td style="text-align:right; letter-spacing: 0.04em; color:#17181D;">' +
                      body.text +
                      "</td></tr>";
                  });

                  innerHtml += '<tr style="letter-spacing: 0.04em; color:#17181D;"><th colspan="2">' + "" + "</th></tr>";

                  innerHtml += '<tr><td colspan="2" style="width: 100%; height: 8px; border-top: 1px solid #17181D"></td></tr>';

                  innerHtml +=
                    '<tr><td style="text-transform: uppercase; letter-spacing: 0.04em;">' + "Benchmark:" + "</td></tr>";

                  bodyLinesBenchmark.forEach(function(body, i) {
                    innerHtml +=
                      '<tr><td style="letter-spacing: 0.04em; color:#717579; margin-right:30px;">' +
                      body.title +
                      '</td><td style="text-align:right; letter-spacing: 0.04em; color:#17181D;">' +
                      body.text +
                      "</td></tr>";
                  });

                  innerHtml +=
                    '<tr><td style="letter-spacing: 0.04em; padding: 12px 0px;">' +
                    "Allokation:" +
                    '</td><td style="letter-spacing: 0.04em; text-align:right;">' +
                    "%" +
                    "</td></tr>";

                  bodyLinesAllocation.forEach(function(body, i) {
                    innerHtml +=
                      '<tr><td style="letter-spacing: 0.04em; color:#717579; margin-right:30px;">' +
                      body.title +
                      '</td><td style="text-align:right; letter-spacing: 0.04em; color:#17181D;">' +
                      body.text +
                      "%" +
                      "</td></tr>";
                  });

                  innerHtml += "</tbody>";

                  var tableRoot = tooltipEl.querySelector("table");
                  tableRoot.innerHTML = innerHtml;
                }

                var position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.background = "white";
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  isDesktop && !isTablet
                    ? position.left + window.pageXOffset + tooltipModel.caretX + 40 + "px"
                    : window.pageXOffset + 150 + "px";
                tooltipEl.style.top =
                  isDesktop && !isTablet ? -position.top + window.pageYOffset + 600 + "px" : window.pageYOffset + 250 + "px";
                tooltipEl.style.font = "Avenir";
                tooltipEl.style.fontSize = "12px";
                tooltipEl.style.lineHeight = "140%";
                tooltipEl.style.fontWeight = "600";
                tooltipEl.style.padding = "16px 16px";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.transition = "opacity 0.2s linear";
                tooltipEl.style.zIndex = "998";
              }
            }
          }
        },
        "en-gb": {
          type: "bar",
          maintainAspectRatio: false,
          responsive: true,
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                }
              }
            },
            hide: {
              animations: {
                x: {
                  to: 0
                }
              }
            }
          },
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false
              }
            },
            y: {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                  return value + "%";
                }
              },
              grid: {
                color: "rgba(31,34,40,1)",
                lineWidth: 2,
                drawBorder: false
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: "top",
              align: "end",
              labels: {
                boxWidth: 12,
                boxHeight: 12,
                font: {
                  family: "Avenir",
                  color: "#717579",
                  size: 12
                },
                padding: 16
              }
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              external: function(context) {
                // Tooltip Element
                var tooltipEl = document.getElementById("chartjs-tooltip");

                // Create element on first render
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-tooltip";
                  tooltipEl.innerHTML = "<table></table>";
                  document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                var tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                // Set Text
                if (tooltipModel.body) {
                  var titleLines = tooltipModel.title || [];
                  //var bodyLines = tooltipModel.body.map(getBody);

                  var bodyLinesPortfolio = [];
                  var bodyLinesBenchmark = [];
                  var bodyLinesAllocation = [];

                  bodyLinesPortfolio.push({
                    title: "Return p.a.",
                    text: tooltipData.portfolio[context.tooltip.dataPoints[0].dataIndex].performance
                  });
                  bodyLinesPortfolio.push({
                    title: "Return/Risk",
                    text: tooltipData.portfolio[context.tooltip.dataPoints[0].dataIndex].perf_risk
                  });
                  bodyLinesPortfolio.push({
                    title: "Max. Drawdown",
                    text: tooltipData.portfolio[context.tooltip.dataPoints[0].dataIndex].drawdown
                  });

                  bodyLinesBenchmark.push({
                    title: "Return p.a.",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].performance
                  });
                  bodyLinesBenchmark.push({
                    title: "Return/Risk",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].perf_risk
                  });
                  bodyLinesBenchmark.push({
                    title: "Max. Drawdown",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].drawdown
                  });

                  bodyLinesAllocation.push({
                    title: "Stocks",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].allocation.split("/")[0]
                  });
                  bodyLinesAllocation.push({
                    title: "Bonds",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].allocation.split("/")[1]
                  });
                  bodyLinesAllocation.push({
                    title: "Commodities",
                    text: tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].allocation.split("/")[2]
                  });

                  var innerHtml = "<thead>";

                  titleLines.forEach(function(title) {
                    innerHtml +=
                      '<tr style="text-transform: uppercase; letter-spacing: 0.04em; color:#17181D; text-align:left"><th colspan="2">' +
                      tooltipData.benchmark[context.tooltip.dataPoints[0].dataIndex].title +
                      "</th></tr>";
                  });

                  innerHtml += '<tr style="letter-spacing: 0.04em; color:#17181D;"><th colspan="2">' + "" + "</th></tr>";

                  innerHtml += "</thead><tbody>";

                  innerHtml += '<tr><td colspan="2" style="width: 100%; height: 8px; border-top: 1px solid #17181D"></td></tr>';

                  bodyLinesPortfolio.forEach(function(body, i) {
                    innerHtml +=
                      '<tr><td style="letter-spacing: 0.04em; color:#717579; margin-right:30px;">' +
                      body.title +
                      '</td><td style="text-align:right; letter-spacing: 0.04em; color:#17181D;">' +
                      body.text +
                      "</td></tr>";
                  });

                  innerHtml += '<tr style="letter-spacing: 0.04em; color:#17181D;"><th colspan="2">' + "" + "</th></tr>";

                  innerHtml += '<tr><td colspan="2" style="width: 100%; height: 8px; border-top: 1px solid #17181D"></td></tr>';

                  innerHtml +=
                    '<tr><td style="text-transform: uppercase; letter-spacing: 0.04em;">' + "Benchmark:" + "</td></tr>";

                  bodyLinesBenchmark.forEach(function(body, i) {
                    innerHtml +=
                      '<tr><td style="letter-spacing: 0.04em; color:#717579; margin-right:30px;">' +
                      body.title +
                      '</td><td style="text-align:right; letter-spacing: 0.04em; color:#17181D;">' +
                      body.text +
                      "</td></tr>";
                  });

                  innerHtml +=
                    '<tr><td style="letter-spacing: 0.04em; padding: 12px 0px;">' +
                    "Allocation:" +
                    '</td><td style="letter-spacing: 0.04em; text-align:right;">' +
                    "%" +
                    "</td></tr>";

                  bodyLinesAllocation.forEach(function(body, i) {
                    innerHtml +=
                      '<tr><td style="letter-spacing: 0.04em; color:#717579; margin-right:30px;">' +
                      body.title +
                      '</td><td style="text-align:right; letter-spacing: 0.04em; color:#17181D;">' +
                      body.text +
                      "%" +
                      "</td></tr>";
                  });

                  innerHtml += "</tbody>";

                  var tableRoot = tooltipEl.querySelector("table");
                  tableRoot.innerHTML = innerHtml;
                }

                var position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.background = "white";
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = "absolute";
                tooltipEl.style.left =
                  isDesktop && !isTablet
                    ? position.left + window.pageXOffset + tooltipModel.caretX + 40 + "px"
                    : window.pageXOffset + 150 + "px";
                tooltipEl.style.top =
                  isDesktop && !isTablet ? -position.top + window.pageYOffset + 600 + "px" : window.pageYOffset + 250 + "px";
                tooltipEl.style.font = "Avenir";
                tooltipEl.style.fontSize = "12px";
                tooltipEl.style.lineHeight = "140%";
                tooltipEl.style.fontWeight = "600";
                tooltipEl.style.padding = "16px 16px";
                tooltipEl.style.pointerEvents = "none";
                tooltipEl.style.transition = "opacity 0.2s linear";
                tooltipEl.style.zIndex = "998";
              }
            }
          }
        }
      }
    };
  }

  componentDidMount() {
    $(".performanceChart")
      .find(".closeBtn")
      .on(
        "click",
        function(event) {
          event.stopPropagation();
          event.preventDefault();

          this.enableScroll();

          $(".titleWrapper").fadeOut();

          $(".performanceChart").fadeOut(
            function() {
              TM.to($("header"), 0.3, {
                y: "0%",
                opacity: 1,
                ease: Power1.easeOut,
                delay: 0,
                onStart: function() {
                  $("#fp-nav").fadeIn();
                },
                onComplete: function() {
                  if (this.state.showChartNo == 1) {
                    barChart.destroy();
                  }
                  this.setState({ isCreatedOnce: false });
                }.bind(this)
              });
            }.bind(this)
          );
        }.bind(this)
      );
  }

  componentWillUnmount() {
    this.enableScroll();
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  disableScroll() {
    if (isDesktop && !isTablet) {
      $("html, body").bind("wheel", function() {
        return false;
      });
    } else {
      disableScroll.on();
    }
  }

  enableScroll() {
    if (isDesktop && !isTablet) {
      $("html, body").off("wheel");
    } else {
      disableScroll.off();
    }
  }

  openChart = (event, no) => {
    this.setState(
      {
        showChartNo: no
      },
      function() {
        if (isDesktop || isTablet) {
          event.stopPropagation();
          event.preventDefault();

          this.disableScroll();

          TM.to($("header"), 0.3, {
            y: "-100%",
            opacity: 0,
            ease: Power1.easeOut,
            delay: 0,
            onStart: function() {
              $("#fp-nav").fadeOut();
            },
            onComplete: function() {
              $(".titleWrapper")
                .delay(300)
                .fadeIn();

              $(".performanceChart").fadeIn(
                function() {
                  if (this.state.showChartNo == 1) {
                    if (!this.state.isCreatedOnce) {
                      var mychart = document.getElementById("canvas").getContext("2d");

                      barChart = new Chart(mychart, {
                        type: "bar",
                        data: this.state.data[this.context.lang == "de-ch" ? "de-ch" : "en-gb"],
                        height: null,
                        width: null,
                        options: this.state.options[this.context.lang == "de-ch" ? "de-ch" : "en-gb"],
                        plugins: [
                          {
                            beforeInit(chart) {
                              // Get reference to the original fit function
                              const originalFit = chart.legend.fit;

                              // Override the fit function
                              chart.legend.fit = function fit() {
                                // Call original function and bind scope in order to use `this` correctly inside it
                                originalFit.bind(chart.legend)();
                                // Change the height as suggested in another answers
                                this.height += 50;
                              };
                            }
                          }
                        ]
                      });

                      this.setState({ isCreatedOnce: true });
                    }
                  }
                }.bind(this)
              );
            }.bind(this)
          });
        }
      }
    );
  };

  render() {
    return (
      <section
        id="performanceSection"
        className="performanceSection"
        style={{
          background: "url('" + this.state.background + "')",
          backgroundPosition: isDesktop ? "0% 0%" : "70% 50%",
          backgroundSize: "cover",
          backgroundColor: "#E5E5E5"
        }}>
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="performanceSection__cont">
            <div className="performanceSection__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="performanceSection__cont_content">
              <span>{this.state.title1}</span>
              <h5 onClick={(e) => this.openChart(e, 1)}>{this.state.text1}</h5>
              <span>{this.state.title2}</span>
              <h5 onClick={(e) => this.openChart(e, 2)}>{this.state.text2}</h5>
            </div>
            <img src={performanceTexture} alt="smartwealth performance icon"></img>
          </div>
        </Observer>
        <div className="performanceChart">
          <div className="performanceChart__cont">
            {this.state.showChartNo == 1 && (
              <>
                <canvas id="canvas" width="100%" height="auto"></canvas>
                <div className="titleWrapper">
                  <h2>{this.state.chartTitle1}</h2>
                </div>
              </>
            )}
            {this.state.showChartNo == 2 && (
              <>
                <img
                  src={this.context.lang == "de-ch" ? staticChartDE : staticChartEN}
                  alt="smartwealth performance icon"
                  className="performanceChart__cont_staticChart"
                />
                <div
                  className="titleWrapper titleWrapper2"
                  style={{
                    top:
                      (isDesktop && !isTablet) || (isTablet && window.innerWidth > window.innerHeight) ? "0" : "calc(32vw - 80px)"
                  }}>
                  <h2>{this.state.chartTitle2}</h2>
                </div>
              </>
            )}
          </div>
          <img alt="smartwealth close icon" src={closeImg} className="closeBtn"></img>
        </div>
      </section>
    );
  }
}
PerformanceComponent.contextType = ContextProviderComponent;
export default PerformanceComponent;
