import React from "react";
import "./uberuns1Component.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import {ContextProviderComponent} from "../../contextProviderComponent";

import { Link } from 'gatsby';

import {
  BrowserView,
  MobileView,
  isBrowser,
  TabletView,
  isMobile,
  isTablet,
  isIPad13,
  isMobileOnly
} from "react-device-detect";

import smallTriangelImg from "../../../images/icons/small_triangel.svg";
import texture2Img from '../../../images/homepage/texture2.png';

class Uberuns1Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      sectionTitle: this.props.slice.primary.section_title.text || "",
      sectionDescription: this.props.slice.primary.section_description.text || "",

      //title1: this.props.slice.primary.title1 ? this.props.slice.primary.title1.text : "",
      //text1: this.props.slice.primary.text1 ? this.props.slice.primary.text1.raw : [],
      //image1: this.props.slice.primary.image1 ? this.props.slice.primary.image1.url : "",
      //buttonText1: this.props.slice.primary.buttontext1 ? this.props.slice.primary.buttontext1.text : "",

      //title2: this.props.slice.primary.title2 ? this.props.slice.primary.title2.text : "",
      //text2: this.props.slice.primary.text2 ? this.props.slice.primary.text2.raw : [],
      //image2: this.props.slice.primary.image2 ? this.props.slice.primary.image2.url : "",
    }
  }

  componentDidMount() {
    
  }

  handleContentVisibility(inView, entry) {

    if (inView) {

      TM.to(
        entry.target.firstChild,
        0.8,
        { 
          opacity: 1,
          ease: Power2.easeInOut,
          delay: 0.0
        }
      );
    }
  }

  render() {
    return (
      <section id="uberuns1Section" className="uberuns1Section">
        <Observer
          threshold="0.1"
          onChange={(inView, entry) => this.handleContentVisibility(inView, entry)}
          className="wrapper"
        >
          <div className="uberuns1Section__cont">
            <div className="uberuns1Section__cont_left">
              <div className="uberuns1Section__cont_left__pageNumber">
                <span>{this.state.sectionNumber}</span>
              </div>
              <div className="uberuns1Section__cont_left__text">
                <span>{(isMobileOnly || (isTablet && (window.innerHeight > window.innerWidth))) ? <span style={{marginRight:'24px'}}>{this.state.sectionNumber}</span> : ''}{this.state.sectionName}</span>
                {this.state.sectionTitle && <h2>{this.state.sectionTitle}</h2>}
                {this.state.sectionDescription && <p>{this.state.sectionDescription}</p>}
              </div>
            </div>
            <div className="uberuns1Section__cont_right"></div>
          </div>
        </Observer>
      </section>
    );
  }
}
Uberuns1Component.contextType = ContextProviderComponent;
export default Uberuns1Component;
