import React from "react";
import "./blogButtonBlock.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { Link } from "gatsby";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import { OutboundLink } from "gatsby-plugin-google-analytics";

class BlogButtonBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.slice.primary.title1 || "",
      text: this.props.slice.primary.description || "",
      button: this.props.slice.primary.buttontext || "",
      link: this.props.slice.primary.link || null
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.text, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="blogButtonBlock" className="blogButtonBlock">
        <Observer threshold="0.0" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="blogButtonBlock_cont" ref={(ref) => (this.text = ref)}>
            <h3 className="blogButtonBlock_cont_title">{this.state.title?.text}</h3>
            <p className="blogButtonBlock_cont_text">{this.state.text?.text}</p>
            <OutboundLink href={this.state.link.url} target="_blank" rel="noopener noreferrer">
              <div className="button blogButtonBlock_cont_button">
                <p>{this.state.button?.text}</p>
              </div>
            </OutboundLink>
          </div>
        </Observer>
      </section>
    );
  }
}
BlogButtonBlock.contextType = ContextProviderComponent;
export default BlogButtonBlock;
