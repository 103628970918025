import React from "react";
import $ from "jquery";
import "./uberuns2Component.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power0, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import { Link } from "gatsby";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { isBrowser, isMobile, isTablet, isDesktop } from "react-device-detect";

import smallTriangelImg from "../../../images/icons/small_triangel.svg";
import texture2Img from "../../../images/homepage/texture2.png";

class Uberuns2Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title1: this.props.slice.primary.title1 ? this.props.slice.primary.title1.text : "",
      text1: this.props.slice.primary.text1 ? this.props.slice.primary.text1.raw : [],
      image1: this.props.slice.primary.image1 ? this.props.slice.primary.image1.url : "",
      buttonText1: this.props.slice.primary.buttontext1 ? this.props.slice.primary.buttontext1.text : "",
      isReversed: this.props.slice.primary.isreversed || false,
      bottom: false
    };
    this.plugins = [ScrollToPlugin];
  }

  componentDidMount() {
    if (isBrowser && !isTablet) {
      this.setState({ bottom: false });
    }
  }

  handleContentVisibility(inView, entry) {
    let section = $(entry.target.firstChild).closest(".uberuns2Section");
    let sectionWrapper = $(entry.target.firstChild).closest(".section");

    let div = $(entry.target.firstChild).find(".uberuns2Section__cont_left__text");
    let scrollDiv = $(entry.target.firstChild).find(".uberuns2Section__cont_left__text_scrollarea");

    let divHeight = $(div).height();
    let scrollDivHeight = $(scrollDiv).height();

    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  disableScroll() {
    $("html, body").bind("wheel", function() {
      return false;
    });
  }

  enableScroll() {
    $("html, body").off("wheel");
  }

  render() {
    return (
      <section id="uberuns2Section" className="uberuns2Section">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="uberuns2Section__cont" style={{ flexDirection: this.state.isReversed ? "row-reverse" : "row" }}>
            <div className="uberuns2Section__cont_left">
              <div className="uberuns2Section__cont_left__pageNumber">
                <span>02</span>
              </div>
              <div className="uberuns2Section__cont_left__text">
                <div className="uberuns2Section__cont_left__text_scrollarea">
                  {this.state.title1 && <h2>{this.state.title1}</h2>}
                  {this.state.text1.map((paragraph, i) => {
                    return <p key={i}>{paragraph.text}</p>;
                  })}

                  {false && this.state.buttonText1 && (
                    <Link to="#" className="textbutton">
                      <p>
                        {this.state.buttonText1}
                        <img src={smallTriangelImg} alt="smartwealth triangle image"></img>
                      </p>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="uberuns2Section__cont_right">
              <img src={this.state.image1} alt="smartwealth image"></img>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
Uberuns2Component.contextType = ContextProviderComponent;
export default Uberuns2Component;
