import React from "react";
import "./unserzielComponent.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import { Link } from "gatsby";

import { isMobile, isTablet, isDesktop } from "react-device-detect";

import smallTriangelImg from "../../../images/icons/small_triangel.svg";
import texture2Img from "../../../images/homepage/texture2.png";

class UnserzielComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      title1: this.props.slice.primary.title1.text || "",
      text1: this.props.slice.primary.text1.raw || [],
      title2: this.props.slice.primary.title2.text || "",
      text2: this.props.slice.primary.text2.raw || [],
      buttonText: this.props.slice.primary.button_text.text
    };
  }

  componentDidMount() {}

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="unserzielSection" className="unserzielSection">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="unserzielSection__cont">
            <div className="unserzielSection__cont__pageNumber">
              <span>{this.state.sectionNumber}</span>
              <span>{this.state.sectionName}</span>
            </div>
            <div className="unserzielSection__cont__text">
              <h2>{this.state.title1}</h2>
              {this.state.text1.map((paragraph, i) => {
                return <p key={i}>{paragraph.text}</p>;
              })}
              <h2>{this.state.title2}</h2>
              {this.state.text2.map((paragraph, i) => {
                return <p key={i}>{paragraph.text}</p>;
              })}
              {false && this.state.buttonText && (
                <Link to="#" className="textbutton">
                  <p>
                    {this.state.buttonText}
                    <img src={smallTriangelImg} alt="smartwealth triangle icon"></img>
                  </p>
                </Link>
              )}
            </div>
          </div>
        </Observer>
        <img src={texture2Img} alt="smartwealth texture icon"></img>
      </section>
    );
  }
}
UnserzielComponent.contextType = ContextProviderComponent;
export default UnserzielComponent;
