import React from "react";
import $ from "jquery";
import Observer from "react-intersection-observer";
import "./partnersComponent.scss";
import { TweenMax as TM, ScrollToPlugin, Power1, Power2, Power3, Power4 } from "gsap/all";
import {ContextProviderComponent} from "../../contextProviderComponent";

import dasHoldingImg from '../../../images/logo/partners/das_holding.png';
import vontobelImg from '../../../images/logo/partners/vontobel.png';
import juliusBarImg from '../../../images/logo/partners/julius_bar.png';
import citibankImg from '../../../images/logo/partners/citibank.png';
import jpmorganImg from '../../../images/logo/partners/jpmorgan.png';

import bankAmericaImg from '../../../images/logo/partners/BankOfAmerica.png';
import ersteImg from '../../../images/logo/partners/Erste.png';
import dekaBankImg from '../../../images/logo/partners/DekaBank.png';
import nomuraImg from '../../../images/logo/partners/Nomura.png';
import societeGeneraleImg from '../../../images/logo/partners/SocieteGenerale.png';

import NHImg from '../../../images/logo/partners/NH.png';
import abuDhabiImg from '../../../images/logo/partners/AbuDhabi.png';
import MUFGImg from '../../../images/logo/partners/MUFG.png';
import GICImg from '../../../images/logo/partners/GIC.png';
import UOBImg from '../../../images/logo/partners/UOB.png';

import LIONImg from '../../../images/logo/partners/LION.png';
import santanderImg from '../../../images/logo/partners/Santander.png';
import raiffeisenImg from '../../../images/logo/partners/Raiffeisenbank.png';
import CQuadratImg from '../../../images/logo/partners/C_QUADRAT.png';
import commerzbankImg from '../../../images/logo/partners/Commerzbank.png';

import LBBWImg from '../../../images/logo/partners/LBBW.png';
import LyxorAssetManagementImg from '../../../images/logo/partners/LyxorAssetManagement.png';
import BankAustriaImg from '../../../images/logo/partners/BankAustria.png';
import RMBImg from '../../../images/logo/partners/RMB.png';
import bloombergImg from '../../../images/logo/partners/BLOOMBERG.png';

import STOXXImg from '../../../images/logo/partners/STOXX.png';
import KeplerFondsImg from '../../../images/logo/partners/KeplerFonds.png';
import SKsecuritiesImg from '../../../images/logo/partners/SK_securities.png';
import VermogensImg from '../../../images/logo/partners/Vermogens.png';
import CompassImg from '../../../images/logo/partners/Compass.png';

import HypothekarbankLenzburgImg from '../../../images/logo/partners/HypothekarbankLenzburg.png';
import SIEMENSImg from '../../../images/logo/partners/SIEMENS.png';
import MaybankIndonesiaImg from '../../../images/logo/partners/MaybankIndonesia.png';
import VWDImg from '../../../images/logo/partners/VWD.png';
import FISImg from '../../../images/logo/partners/FIS.png';

import EuroImg from '../../../images/logo/partners/Euro.png';
import ISPImg from '../../../images/logo/partners/ISP.png';
import MountainViewImg from '../../../images/logo/partners/MountainView.png';
import InteractiveBrokersImg from '../../../images/logo/partners/INTERACTIVE_BROKERS.png';
import IntertrustImg from '../../../images/logo/partners/Intertrust.png';


import lineVectorImg from '../../../images/homepage/line_vector.svg';
import seeMoreImg from '../../../images/homepage/see_more.svg';
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";


class PerformanceComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      text: this.props.slice.primary.text.text || "",
    }
    this.plugins = [ScrollToPlugin];
  }

  componentDidMount() {
    
    if((isDesktop && !isTablet) || (isTablet && (window.innerWidth > window.innerHeight))) {

      $('.partnersSection__cont_content_seemore').on('click', function(event) {

        $(event.target).fadeOut(function() {

          /*TM.to(
            $('.partnersSection__cont'),
            0.6,
            {
              ease: Power2.easeOut,
              paddingTop: '100px',
            }
          );*/

          TM.to(
            $('.partnersSection__cont_content').find('> p'),
            0.4,
            {
              ease: Power2.easeOut,
              opacity: 0,
              y: '-20px',
            }
          )

          TM.to(
            $('.partnersSection__cont_content').find('> p'),
            1,
            {
              ease: Power2.easeInOut,
              height: 0,
              marginTop: 0,
              marginBottom: 0,
              delay: 0.1,
              onStart: function() {
                TM.to(
                  $('.beginlogo, .morelogo'),
                  0.6,
                  {
                    ease: Power2.easeOut,
                    width: '14.2%',
                    minWidth: '14.2%',
                    height: 80,
                    padding: '1vh 2%'
                  }
                );

                TM.to(
                  $('.partnersSection__cont_content_logos'),
                  0.6,
                  {
                    ease: Power2.easeOut,
                    width: '104%',
                    x: '-2%'
                  }
                );
                
              },
              onComplete: function() {

                $('.partnersSection__cont_content').find('> p').css('display', 'none');
                //$('.partnersSection__cont_content_logos').css('margin-bottom', 0);
                //$('.partnersSection__cont_content_morelogos').css('display', 'flex');

                $('.morelogo').css('display', 'flex').each((index, element) => {
                  TM.to(
                    $(element),
                    0.4,
                    {
                      ease: Power2.easeOut,
                      opacity: 1,
                      delay: (index * 0.02),
                    }
                  );
                });
      
              }
            }
          );

          

        });

      });

    } else if (isMobileOnly || (isTablet && (window.innerWidth < window.innerHeight))) {

      $('.partnersSection__cont_content_seemore').on('click', function(event) {

        $(event.target).fadeOut(function() {

          $('.morelogo').css('display', 'flex').each((index, element) => {
            TM.to(
              $(element),
              0.4,
              {
                ease: Power2.easeOut,
                opacity: 1,
                delay: (index * 0.02),
              }
            );
          });

        });

      });

    }
  }

  handleContentVisibility(inView, entry) {

    if (inView) {

      TM.to(
        entry.target.firstChild,
        0.8,
        { 
          opacity: 1,
          ease: Power2.easeInOut,
          delay: 0.0
        }
      );

      TM.to(
        $(entry.target.firstChild).find('.partnersSection__cont_vector_overlay'),
        1,
        { 
          left: '100vw',
          ease: Power1.easeOut,
          delay: 0.6
        }
      );

    }
  }

  render() {

    return (
        <section id="partnersSection" className="partnersSection">
          <Observer
            threshold="0.1"
            onChange={(inView, entry) => this.handleContentVisibility(inView, entry)}
            className="wrapper"
          >
            <div className="partnersSection__cont">
              <div className="partnersSection__cont_pageNumber">
                <span>{this.state.sectionNumber}</span>
              </div>
              <div className="partnersSection__cont_content">
                <span>{isMobileOnly || (isTablet && (window.innerHeight > window.innerWidth)) ? <span style={{marginRight: '24px'}}>{this.state.sectionNumber}</span> : ''}{this.state.sectionName}</span>
                <p>{this.state.text}</p>


                    <div className="partnersSection__cont_content_logos">

                      <div className="beginlogo"><img src={dasHoldingImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={vontobelImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={juliusBarImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={citibankImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={jpmorganImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={bankAmericaImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={ersteImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={dekaBankImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={nomuraImg} alt="smartwealth client logo"></img></div>
                      <div className="beginlogo"><img src={societeGeneraleImg} alt="smartwealth client logo"></img></div>


                      <div className="morelogo"><img src={NHImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={abuDhabiImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={MUFGImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={GICImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={UOBImg} alt="smartwealth client logo"></img></div>

                      <div className="morelogo"><img src={LIONImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={santanderImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={raiffeisenImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={CQuadratImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={commerzbankImg} alt="smartwealth client logo"></img></div>

                      <div className="morelogo"><img src={LBBWImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={LyxorAssetManagementImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={BankAustriaImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={RMBImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={bloombergImg} alt="smartwealth client logo"></img></div>

                      <div className="morelogo"><img src={STOXXImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={KeplerFondsImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={SKsecuritiesImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={VermogensImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={CompassImg} alt="smartwealth client logo"></img></div>

                      <div className="morelogo"><img src={HypothekarbankLenzburgImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={SIEMENSImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={MaybankIndonesiaImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={VWDImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={FISImg} alt="smartwealth client logo"></img></div>

                      <div className="morelogo"><img src={EuroImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={ISPImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={MountainViewImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={InteractiveBrokersImg} alt="smartwealth client logo"></img></div>
                      <div className="morelogo"><img src={IntertrustImg} alt="smartwealth client logo"></img></div>

                    </div>
                  

                <img className="partnersSection__cont_content_seemore" src={seeMoreImg} alt="smartwealth see more icon"></img>
                
              </div>

              <div className="partnersSection__cont_vector">
                <div className="partnersSection__cont_vector_overlay"></div>
                <img src={lineVectorImg} alt="smartwealth client logo"></img>
              </div>
            </div>
          </Observer>
        </section>
    );
  }
}
PerformanceComponent.contextType = ContextProviderComponent;
export default PerformanceComponent;
