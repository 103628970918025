import React from "react";
import $ from "jquery";
import Observer from "react-intersection-observer";
import "./philosophieComponent.scss";

import { TweenMax as TM, Power1, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";

import philosophieTexture from "../../../images/whysmartwealth/philosophie_texture.png";

class PhilosophieComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      sectionTitle: this.props.slice.primary.section_title.text || "",
      philosophies: this.props.slice.items || []
    };
  }

  componentDidMount() {
    if (isBrowser && !isTablet) {
    }
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });

      TM.to($(entry.target.firstChild).find(".philosophieSection__cont_vector_overlay"), 1, {
        left: "100vw",
        ease: Power1.easeOut,
        delay: 0.6
      });
    }
  }

  render() {
    return (
      <section id="philosophieSection" className="philosophieSection">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="philosophieSection__cont">
            <div className="philosophieSection__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="philosophieSection__cont_content">
              {isMobileOnly ||
                (isTablet && window.innerHeight > window.innerWidth && (
                  <div style={{ display: "flex" }}>
                    <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                    <span>{this.state.sectionName}</span>
                  </div>
                ))}
              {((isDesktop && !isTablet) || (isTablet && window.innerHeight < window.innerWidth)) && (
                <span>{this.state.sectionName}</span>
              )}
              {this.state.sectionTitle && <h2>{this.state.sectionTitle}</h2>}

              <div className="philosophieSection__cont_content__philosophies">
                {this.state.philosophies.map((philosophyItem, i) => {
                  return (
                    <div key={i} className="philosophieSection__cont_content__philosophies_item">
                      <span>{i < 9 ? "0" + (i + 1) : i + 1}</span>
                      <h3>{philosophyItem.item_title.text}</h3>
                      <p>{philosophyItem.item_text.text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="philosophieSection__cont_vector">
              <div className="philosophieSection__cont_vector_overlay"></div>
              <img src={philosophieTexture} alt="vector image"></img>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
PhilosophieComponent.contextType = ContextProviderComponent;
export default PhilosophieComponent;
