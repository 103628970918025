import React from 'react';
import './testimonialLargeComponent.scss';
import Observer from 'react-intersection-observer';
import {TweenMax as TM, Power1, Power2, Power3, gsap} from 'gsap/all';
import {ContextProviderComponent} from '../../contextProviderComponent';

import {Link} from 'gatsby';

import {BrowserView, MobileView, isBrowser, TabletView, isMobile, isTablet, isIPad13, isMobileOnly} from 'react-device-detect';

import testimonialLargeImg from '../../../images/homepage/testimonialLarge.jpg';
import smallTriangelImg from '../../../images/icons/small_triangel.svg';
import texture2Img from '../../../images/homepage/texture2.png';

class TestimonialLargeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionNumber: this.props.slice.primary.section_number?.text || '',
            sectionName: this.props.slice.primary.section_name?.text || '',
            image: this.props.slice.primary.section_image?.url ? this.props.slice.primary.section_image.url : testimonialLargeImg,
            title: this.props.slice.primary.title1 ? this.props.slice.primary.title1.text : '',
            text1: this.props.slice.primary.text1 ? this.props.slice.primary.text1.text : '',
            text2: this.props.slice.primary.text2 ? this.props.slice.primary.text2.text : '',
            button: this.props.slice.primary.button1 ? this.props.slice.primary.button1.text : '',
            textButton: this.props.slice.primary.text_button ? this.props.slice.primary.text_button.text : '',
            buttonLink: this.props.slice.primary.button_link ? this.props.slice.primary.button_link.url : '',
        };
    }

    getElementsToAnimate = () => {
        let array = [];
        this.title && array.push(this.title);
        this.text1 && array.push(this.text1);
        this.text2 && array.push(this.text2);
        this.button && array.push(this.button);
        this.textButton && array.push(this.textButton);
        return array;
    };

    componentDidMount() {
        setTimeout(() => {
            const arrayOfElementsToAnimate = this.getElementsToAnimate();
            gsap.set(arrayOfElementsToAnimate, {opacity: 0, y: 50});
        }, 500);
    }

    handleContentVisibility(inView, entry) {
        if (inView) {
            const arrayOfElementsToAnimate = this.getElementsToAnimate();
            let delay = 0.4;
            arrayOfElementsToAnimate.forEach(el => {
                TM.to(el, 1, {
                    opacity: 1,
                    y: 0,
                    ease: Power3.easeOut,
                    delay: delay,
                });
                delay = delay + 0.1;
            });
        }
    }

    render() {
        return (
            <section id="testimonialLarge" className="testimonialLarge">
                <Observer
                    threshold="0.1"
                    onChange={(inView, entry) => this.handleContentVisibility(inView, entry)}
                    className="wrapper"
                >
                    <div className="testimonialLarge__cont">
                        <div className="testimonialLarge__cont_left">
                            <img src={texture2Img} alt="smartwealth texture image"></img>
                            <div className="testimonialLarge__cont_left__pageNumber">
                                <span>{this.state.sectionNumber}</span>
                            </div>
                            <div className="testimonialLarge__cont_left__text">
                                <span>
                                    {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                                        <span style={{marginRight: '24px'}}>{this.state.sectionNumber}</span>
                                    ) : (
                                        ''
                                    )}
                                    {this.state.sectionName}
                                </span>
                                {this.state.title && (
                                    <h2
                                        ref={ref => {
                                            this.title = ref;
                                        }}
                                    >
                                        {this.state.title}
                                    </h2>
                                )}
                                {this.state.text1 && (
                                    <p
                                        ref={ref => {
                                            this.text1 = ref;
                                        }}
                                    >
                                        {this.state.text1}
                                    </p>
                                )}
                                {this.state.text2 && (
                                    <p
                                        ref={ref => {
                                            this.text2 = ref;
                                        }}
                                    >
                                        {this.state.text2}
                                    </p>
                                )}
                                {this.state.button && (
                                    <div
                                        ref={ref => {
                                            this.button = ref;
                                        }}
                                        className="button button-hoverBlue"
                                        onClick={() =>
                                            this.state.buttonLink != ''
                                                ? window.open(this.state.buttonLink, '_blank')
                                                : this.context.callBackForCalendlyModalShow('flex')
                                        }
                                    >
                                        <p>{this.state.button}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* <div className="testimonialLarge__cont_right" 
              style={{background: "url('" + this.state.image + "')", backgroundSize: "cover", backgroundColor: "#E5E5E5", backgroundRepeat: "no-repeat"}}>
            </div> */}
                        <img
                            className="testimonialLarge__cont_right"
                            src={this.state.image}
                            alt="smartwealth testimonial image"
                        />
                    </div>
                </Observer>
            </section>
        );
    }
}
TestimonialLargeComponent.contextType = ContextProviderComponent;
export default TestimonialLargeComponent;
