import React, { useEffect, useRef, useContext } from "react";
import "./strategyBasicTab.scss";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import { ContextProviderComponent } from "../../../contextProviderComponent";

const StrategyBasicTab = (props) => {
  const { title, text, image, showButton } = props;
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const imageRef = useRef(null);
  const buttonRef = useRef(null);
  const context = useContext(ContextProviderComponent);

  useEffect(() => {
    gsap.set([titleRef.current, textRef.current, buttonRef.current], { opacity: 0, y: 50 });
    gsap.set([imageRef.current], { opacity: 0, x: 50 });
  }, []);

  function handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(titleRef.current, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(textRef.current, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(buttonRef.current, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.6
      });
      TM.to(imageRef.current, 1, {
        opacity: 1,
        x: 0,
        ease: Power3.easeOut,
        delay: 0.6
      });
    }
  }

  return (
    <div className="strategyTab">
      <Observer threshold="0.1" onChange={(inView, entry) => handleContentVisibility(inView, entry)} className="wrapper">
        <div className="strategyTab_slide">
          <div className="strategyTab_slide_text">
            <h3 ref={titleRef}>{title}</h3>
            <p ref={textRef}>{text}</p>
            {showButton && (
              <div
                ref={buttonRef}
                className="button button-hoverBlue strategyTab_slide_text_mainButton"
                onClick={() => context.callBackForCalendlyModalShow("flex")}>
                <p>FIND YOUR APPROPIATE STRATEGY</p>
              </div>
            )}
          </div>
          <div className="strategyTab_slide_image">
            <img ref={imageRef} src={image} alt="smartwealth slide image" />
          </div>
        </div>
      </Observer>
    </div>
  );
};
export default StrategyBasicTab;
