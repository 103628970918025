import React from "react";
import "./tilesComponent.scss";
import Observer from "react-intersection-observer";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";
import cell1Img from "../../../images/b2b/tile_icon1.svg";
import cell2Img from "../../../images/b2b/tile_icon2.svg";
import cell3Img from "../../../images/b2b/tile_icon3.svg";
import cell4Img from "../../../images/b2b/tile_icon4.svg";
import cell5Img from "../../../images/b2b/tile_icon5.svg";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

class TilesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text,
      sectionName: this.props.slice.primary.section_title.text,
      title: this.props.slice.primary.title.text,
      whiteLabelDescription: this.props.slice.primary.whitelabel_description.text,
      whiteLabelTitle: this.props.slice.primary.whitelabel_title.text,
      description: this.props.slice.primary.description.text,
      items: this.props.slice.items
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set(
      [this.title, this.subtitle, this.textCell, this.iconCell1, this.iconCell2, this.iconCell3, this.iconCell4, this.iconCell5],
      { opacity: 0, y: 50 }
    );
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.subtitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(this.textCell, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.6
      });
      TM.to(this.iconCell2, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.7
      });
      TM.to(this.iconCell5, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.8
      });
      TM.to(this.iconCell3, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.9
      });
      TM.to(this.iconCell1, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 1
      });
      TM.to(this.iconCell4, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 1
      });
    }
  }

  render() {
    return (
      <section id="tilesComponent" className="tilesComponent">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="tilesComponent__cont">
            <div className="tilesComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="tilesComponent__cont_contentWrapper">
              <div className="tilesComponent__cont_contentWrapper_content">
                <span className="tilesComponent__cont_contentWrapper_content_pageTitle">
                  {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                    <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                  ) : (
                    ""
                  )}
                  {this.state.sectionName}
                </span>
                <h2
                  ref={(ref) => {
                    this.title = ref;
                  }}
                  className="tilesComponent__cont_contentWrapper_content_title">
                  {this.state.title}
                </h2>
                <h3
                  ref={(ref) => {
                    this.subtitle = ref;
                  }}
                  className="tilesComponent__cont_contentWrapper_content_subtitle">
                  {this.state.description}
                </h3>
                <div className="tilesComponent__cont_contentWrapper_content_tilesCont">
                  <div className="tilesComponent__cont_contentWrapper_content_tilesCont_row tilesComponent__cont_contentWrapper_content_tilesCont_row1">
                    <div
                      ref={(ref) => {
                        this.textCell = ref;
                      }}
                      className="tilesComponent__cont_contentWrapper_content_tilesCont_row_textCell">
                      <h3>{this.state.whiteLabelTitle}</h3>
                      <p>{this.state.whiteLabelDescription}</p>
                    </div>
                    <div
                      ref={(ref) => {
                        this.iconCell1 = ref;
                      }}
                      className="tilesComponent__cont_contentWrapper_content_tilesCont_row_iconCell">
                      <img src={cell1Img} alt="smartwealth tile image" />
                      <p>{this.state.items[0].text.text}</p>
                    </div>
                  </div>
                  <div className="tilesComponent__cont_contentWrapper_content_tilesCont_row tilesComponent__cont_contentWrapper_content_tilesCont_row2">
                    <div
                      ref={(ref) => {
                        this.iconCell2 = ref;
                      }}
                      className="tilesComponent__cont_contentWrapper_content_tilesCont_row_iconCell">
                      <img src={cell2Img} alt="smartwealth tile image" />
                      <p>{this.state.items[1].text.text}</p>
                    </div>
                    <div className="tilesComponent__cont_contentWrapper_content_tilesCont_row2_rightCont">
                      <div
                        ref={(ref) => {
                          this.iconCell3 = ref;
                        }}
                        className="tilesComponent__cont_contentWrapper_content_tilesCont_row_iconCell">
                        <img src={cell3Img} alt="smartwealth tile image" />
                        <p>{this.state.items[2].text.text}</p>
                      </div>
                      <div
                        ref={(ref) => {
                          this.iconCell4 = ref;
                        }}
                        className="tilesComponent__cont_contentWrapper_content_tilesCont_row_iconCell">
                        <img src={cell4Img} alt="smartwealth tile image" />
                        <p>{this.state.items[3].text.text}</p>
                      </div>
                    </div>
                  </div>
                  <div className="tilesComponent__cont_contentWrapper_content_tilesCont_row tilesComponent__cont_contentWrapper_content_tilesCont_row3">
                    <div
                      ref={(ref) => {
                        this.iconCell5 = ref;
                      }}
                      className="tilesComponent__cont_contentWrapper_content_tilesCont_row_iconCell">
                      <img src={cell5Img} alt="smartwealth tile image" />
                      <p>{this.state.items[4].text.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}

TilesComponent.contextType = ContextProviderComponent;
export default TilesComponent;
