import React from "react";
import "./numberTextSliderComponent.scss";
import Observer from "react-intersection-observer";
import Slider from "react-slick";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";
import { ContextProviderComponent } from "../../contextProviderComponent";
import prevIcon from "../../../images/icons/prev.svg";
import nextIcon from "../../../images/icons/next.svg";
import $ from "jquery";

const SliderItem = ({ item, index }) => {
  return (
    <div className="numberTextSliderComponent__cont_content_sliderCont_slide">
      <span>{index + 1}.</span>
      <div className="numberTextSliderComponent__cont_content_sliderCont_slide_textCont">
        <p>{item.slider_text.text}</p>
      </div>
    </div>
  );
};

class NumberTextSliderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_title.text || "",
      title: this.props.slice.primary.title.text,
      subtitle: this.props.slice.primary.description.text,
      slides: this.props.slice.items,
      index: 0,
      wait: false
    };
    this.itemsRefs = [this.props.slice.items.length];
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set([this.title, this.subtitle], { opacity: 0, y: 50 });

    // this.itemsRefs.forEach((item) => {
    //   gsap.set(item, { opacity: 0, x: -50 });
    // });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.subtitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(this.slider, 1, {
        opacity: 1,
        ease: Power3.easeOut,
        delay: 0.6
      });
      // var delay = 0.2;
      // this.itemsRefs.forEach((item) => {
      //   delay = delay + 0.1;
      //   TM.to(item, 0.5, {
      //     opacity: 1,
      //     x: 0,
      //     ease: Power3.easeOut,
      //     delay: delay
      //   });
      // });
    }
  }

  previousSlide = () => {
    this.slider.slickPrev();
  };
  nextSlide = () => {
    this.slider.slickNext();
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      afterChange: (current) => this.setState({ index: current })
    };
    return (
      <section id="numberTextSliderComponent" className="numberTextSliderComponent">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="numberTextSliderComponent__cont">
            <div className="numberTextSliderComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="numberTextSliderComponent__cont_content">
              <span className="numberTextSliderComponent__cont_content_pageTitle">
                {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                  <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                ) : (
                  ""
                )}
                {this.state.sectionName}
              </span>
              <h2
                ref={(ref) => {
                  this.title = ref;
                }}
                className="numberTextSliderComponent__cont_content_title">
                {this.state.title}
              </h2>
              <h3
                ref={(ref) => {
                  this.subtitle = ref;
                }}
                className="numberTextSliderComponent__cont_content_subtitle">
                {this.state.subtitle}
              </h3>
              <div className="numberTextSliderComponent__cont_content_sliderCont">
                {this.state.slides.length > 0 && (
                  <Slider ref={(ref) => (this.slider = ref)} {...settings}>
                    {this.state.slides.map((slide, index) => {
                      return <SliderItem item={slide} index={index} />;
                    })}
                  </Slider>
                )}
              </div>
              <div className="numberTextSliderComponent__cont_content_controls">
                <div className="numberTextSliderComponent__cont_content_controls_buttons">
                  <img
                    className="numberTextSliderComponent__cont_content_controls_buttons--prev"
                    alt="smartwealth previous arrow icon"
                    src={prevIcon}
                    onClick={this.previousSlide}
                    style={{ opacity: this.state.index == 0 ? 0.3 : 1 }}
                  />
                  <img
                    className="numberTextSliderComponent__cont_content_controls_buttons--next"
                    alt="smartwealth next arrow icon"
                    src={nextIcon}
                    onClick={this.nextSlide}
                    style={{ opacity: this.state.index + 1 == this.state.slides.length ? 0.3 : 1 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}
NumberTextSliderComponent.contextType = ContextProviderComponent;
export default NumberTextSliderComponent;
