import React, { useEffect, useRef, useState, useContext } from "react";
import "./strategySliderTab.scss";
import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import Observer from "react-intersection-observer";
import prevIcon from "../../../../images/icons/prev.svg";
import nextIcon from "../../../../images/icons/next.svg";
import { ContextProviderComponent } from "../../../contextProviderComponent";

const StrategySliderTab = (props) => {
  const { title, text, imageSlides, showButton = true } = props;
  const [slideIndex, setSlideIndex] = useState(0);
  const titleRef = useRef(null);
  const textRef = useRef(null);
  const slideRef = useRef(null);
  const buttonRef = useRef(null);
  const controlsRef = useRef(null);
  const context = useContext(ContextProviderComponent);

  useEffect(() => {
    gsap.set([titleRef.current, textRef.current, buttonRef.current, controlsRef.current], { opacity: 0, y: 50 });
    gsap.set(slideRef.current, { opacity: 0, x: 50 });
  }, []);

  function slidePrevious() {
    let newIndex = slideIndex - 1;

    if (newIndex < 0) {
      newIndex += imageSlides.length;
    }

    TM.to(slideRef.current, 0.3, {
      x: "20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        setSlideIndex(newIndex);

        TM.to(slideRef.current, 0.05, {
          x: "-20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(slideRef.current, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.1
            });
          }.bind(this)
        });
      }.bind(this)
    });

    TM.to(textRef.current, 0.3, {
      x: "20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        setSlideIndex(newIndex);

        TM.to(textRef.current, 0.05, {
          x: "-20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(textRef.current, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.1
            });
          }.bind(this)
        });
      }.bind(this)
    });
  }

  function slideNext() {
    let newIndex = (slideIndex + 1) % imageSlides.length;

    TM.to(slideRef.current, 0.3, {
      x: "-20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        setSlideIndex(newIndex);

        TM.to(slideRef.current, 0.05, {
          x: "20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(slideRef.current, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.1
            });
          }.bind(this)
        });
      }.bind(this)
    });

    TM.to(textRef.current, 0.3, {
      x: "-20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        TM.to(textRef.current, 0.05, {
          x: "20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to(textRef.current, 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.1
            });
          }.bind(this)
        });
      }.bind(this)
    });
  }

  function handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(titleRef.current, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(textRef.current, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(controlsRef.current, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.6
      });
      TM.to(buttonRef.current, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.7
      });
      TM.to(slideRef.current, 1, {
        opacity: 1,
        x: 0,
        ease: Power3.easeOut,
        delay: 0.7
      });
    }
  }

  return (
    <div className="strategySliderTab">
      <Observer threshold="0.1" onChange={(inView, entry) => handleContentVisibility(inView, entry)} className="wrapper">
        <div className="strategySliderTab_slide">
          <div className="strategySliderTab_slide_text">
            <h3 ref={titleRef}>{Array.isArray(title) ? title[slideIndex] : title}</h3>
            <p ref={textRef}>{Array.isArray(text) ? text[slideIndex] : text}</p>
            <div ref={controlsRef} className="strategySliderTab_slide_text_controls">
              <div className="strategySliderTab_slide_text_controls_buttons">
                <img
                  className="strategySliderTab_slide_text_controls_buttons--prev"
                  alt="smartwealth previous icon"
                  src={prevIcon}
                  style={{ opacity: slideIndex == 0 ? 0.3 : 1 }}
                  onClick={() => {
                    slideIndex > 0 && slidePrevious();
                  }}
                />
                <img
                  className="strategySliderTab_slide_text_controls_buttons--next"
                  alt="smartwealth next icon"
                  src={nextIcon}
                  style={{ opacity: slideIndex + 1 == imageSlides.length ? 0.3 : 1 }}
                  onClick={() => {
                    slideIndex + 1 < imageSlides.length && slideNext();
                  }}
                />
              </div>
              <div className="strategySliderTab_slide_text_controls_dotCont">
                {imageSlides.map((slide, index) => {
                  return (
                    <div
                      className="strategySliderTab_slide_text_controls_dotCont_dot"
                      style={{ opacity: slideIndex == index ? "1" : "0.32" }}></div>
                  );
                })}
              </div>
            </div>
            {showButton && (
              <div
                ref={buttonRef}
                className="button button-hoverBlue strategySliderTab_slide_text_mainButton"
                onClick={() => context.callBackForCalendlyModalShow("flex")}>
                <p>FIND YOUR APPROPIATE STRATEGY</p>
              </div>
            )}
          </div>
          <div ref={slideRef} className="strategySliderTab_slide_image">
            <img src={imageSlides[slideIndex]} alt="smartwealth slide image" />
          </div>
        </div>
      </Observer>
    </div>
  );
};

export default StrategySliderTab;
