import React from "react";
import "./addedValuesComponent.scss";
import Observer from "react-intersection-observer";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";
import whiteCheckMark from "../../../images/icons/circle-check-white.svg";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

class AddedValuesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text,
      sectionName: this.props.slice.primary.section_title.text,
      title: this.props.slice.primary.title.text,
      description: this.props.slice.primary.description.text,
      image: this.props.slice.primary.image.url,
      items: this.props.slice.items
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set([this.title, this.subtitle, this.list, this.image], { opacity: 0, y: 50 });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.subtitle, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(this.list, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.6
      });
      TM.to(this.image, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.7
      });
    }
  }

  render() {
    return (
      <section id="addedValuesComponent" className="addedValuesComponent">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="addedValuesComponent__cont">
            <div className="addedValuesComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="addedValuesComponent__cont_contentWrapper">
              <div className="addedValuesComponent__cont_contentWrapper_content">
                <span className="addedValuesComponent__cont_contentWrapper_content_pageTitle">
                  {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                    <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                  ) : (
                    ""
                  )}
                  {this.state.sectionName}
                </span>
                <h2
                  ref={(ref) => {
                    this.title = ref;
                  }}
                  className="addedValuesComponent__cont_contentWrapper_content_title">
                  {this.state.title}
                </h2>
                <h3
                  ref={(ref) => {
                    this.subtitle = ref;
                  }}
                  className="addedValuesComponent__cont_contentWrapper_content_subtitle">
                  {this.state.description}
                </h3>
              </div>
              <div
                ref={(ref) => {
                  this.list = ref;
                }}
                className="addedValuesComponent__cont_contentWrapper_list">
                {this.state.items.map((item) => {
                  return (
                    <div className="addedValuesComponent__cont_contentWrapper_list_item">
                      <img alt={"checkmark"} src={whiteCheckMark} />
                      <p>{item.text.text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <img
            alt={"smartwealth image"}
            ref={(ref) => {
              this.image = ref;
            }}
            src={this.state.image}
            className="addedValuesComponent_bottomImage"
          />
        </Observer>
      </section>
    );
  }
}

AddedValuesComponent.contextType = ContextProviderComponent;
export default AddedValuesComponent;
