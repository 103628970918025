import React from "react";
import $ from "jquery";
import "./referenceComponent.scss";
import Observer from "react-intersection-observer";

import { TweenMax as TM, Power1, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import referenceTexture from "../../../images/homepage/reference_texture.svg";
import referenceImg from "../../../images/homepage/reference_image.jpg";
import prevIcon from "../../../images/icons/prev.svg";
import nextIcon from "../../../images/icons/next.svg";
import closeImg from "../../../images/icons/hide.svg";
import { isBrowser, isMobile, isTablet, isDesktop, isMobileOnly } from "react-device-detect";

class referenceSectionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text,
      sectionName: this.props.slice.primary.section_name.text,
      references: this.props.slice.items,
      index: 0,
      wait: false
    };
  }

  componentDidMount() {
    this.setState({ index: 0 });

    $(".referenceSection__cont_right__content_controls--prev").on(
      "click",
      function(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.setState.wait) {
          this.setState({ wait: true });
          this.slidePrevious();
        }
      }.bind(this)
    );

    $(".referenceSection__cont_right__content_controls--next").on(
      "click",
      function(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.setState.wait) {
          this.setState({ wait: true });
          this.slideNext();
        }
      }.bind(this)
    );

    if (isBrowser && !isTablet) {
      $(".referenceSection__cont_right__content_text")
        .find("a")
        .on(
          "click",
          function(event) {
            this.showDetail();
          }.bind(this)
        );

      $(".referenceDetail__close, .referenceDetail .closeBtn").on(
        "click",
        function(event) {
          this.hideDetail();
        }.bind(this)
      );
    }
  }

  showDetail() {
    TM.to($("header"), 0.4, {
      y: "-100%",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onStart: function() {
        this.disableScroll();
        $("#fp-nav").fadeOut();
      }.bind(this),
      onComplete: function() {
        $(".referenceDetail").fadeIn();
        TM.to($(".referenceDetail__cont"), 0.4, {
          x: "0px",
          ease: Power2.easeOut,
          delay: 0.2
        });
      }
    });
  }

  hideDetail() {
    TM.to($(".referenceDetail__cont"), 0.4, {
      x: "-100%",
      ease: Power2.easeOut,
      onComplete: function() {
        $(".referenceDetail").fadeOut();
        TM.to($("header"), 0.4, {
          y: "0%",
          opacity: 1,
          ease: Power2.easeOut,
          onComplete: function() {
            this.enableScroll();
            $("#fp-nav").fadeIn();
          }.bind(this)
        });
      }.bind(this)
    });
  }

  slidePrevious() {
    let newIndex = this.state.index - 1;

    if (newIndex < 0) {
      newIndex += this.state.references.length;
    }

    TM.to($(".referenceSection__cont_right__content_text, .referenceSection__cont_left"), 0.3, {
      x: "20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to($(".referenceSection__cont_right__content_text, .referenceSection__cont_left"), 0.05, {
          x: "-20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to($(".referenceSection__cont_right__content_text, .referenceSection__cont_left"), 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3,
              onComplete: function() {
                this.setState({ wait: false });
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
  }

  slideNext() {
    let newIndex = (this.state.index + 1) % this.state.references.length;

    TM.to($(".referenceSection__cont_right__content_text, .referenceSection__cont_left"), 0.3, {
      x: "-20px",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to($(".referenceSection__cont_right__content_text, .referenceSection__cont_left"), 0.05, {
          x: "20px",
          ease: Power2.easeOut,
          onComplete: function() {
            TM.to($(".referenceSection__cont_right__content_text, .referenceSection__cont_left"), 0.3, {
              x: "0%",
              opacity: 1,
              ease: Power2.easeOut,
              delay: 0.3,
              onComplete: function() {
                this.setState({ wait: false });
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });

      TM.to($(entry.target.firstChild).find(".referenceSection__cont_vector_overlay"), 1, {
        left: "100vw",
        ease: Power1.easeOut,
        delay: 0.6
      });
    }
  }

  componentWillUnmount() {
    this.enableScroll();
  }

  disableScroll() {
    $("html, body").bind("wheel", function() {
      return false;
    });
  }

  enableScroll() {
    $("html, body").off("wheel");
  }

  render() {
    return (
      <section id="referenceSection" className="referenceSection">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="referenceSection__cont">
            <div className="referenceSection__cont_left">
              <img src={this.state.references[this.state.index].image.url} alt="smartwealth image"></img>
            </div>
            <div className="referenceSection__cont_right">
              <div className="referenceSection__cont_right__pageNumber">
                <span>{this.state.sectionNumber}</span>
                <span>{this.state.sectionName}</span>
              </div>
              <div className="referenceSection__cont_right__content">
                <div className="referenceSection__cont_right__content_text">
                  <div>
                    <a>{this.state.references[this.state.index].text.text}</a>
                  </div>
                  {((isDesktop && !isTablet) || (isTablet && window.innerWidth > window.innerHeight)) && (
                    <div>
                      <h4>{this.state.references[this.state.index].person_name.text}</h4>
                      {this.state.references[this.state.index].person_position.raw.map((person_position, i) => {
                        return <h5 key={i}>{person_position.text}</h5>;
                      })}
                    </div>
                  )}
                  {(isMobileOnly || (isTablet && window.innerWidth < window.innerHeight)) && (
                    <div>
                      <img src={this.state.references[this.state.index].image.url} alt="smartwealth image"></img>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h4>{this.state.references[this.state.index].person_name.text}</h4>
                        {this.state.references[this.state.index].person_position.raw.map((person_position, i) => {
                          return <h5 key={i}>{person_position.text}</h5>;
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className="referenceSection__cont_right__content_controls">
                  <img
                    className="referenceSection__cont_right__content_controls--prev"
                    src={prevIcon}
                    alt="smartwealth previous icon"></img>
                  <img
                    className="referenceSection__cont_right__content_controls--next"
                    src={nextIcon}
                    alt="smartwealth next icon"></img>
                </div>
              </div>
            </div>

            <div className="referenceSection__cont_vector">
              <div className="referenceSection__cont_vector_overlay"></div>
              <img src={referenceTexture} alt="smartwealth vector"></img>
            </div>
          </div>
        </Observer>

        <div className="referenceDetail">
          <div className="referenceDetail__cont">
            <div className="referenceDetail__cont__header">
              <img src={closeImg} className="closeBtn" alt="smartwealth close icon"></img>
              <span className="referenceDetail__cont__header_text referenceDetail__cont__header_text-colorful">
                {this.state.sectionName}
              </span>
              <span className="referenceDetail__cont__header_text">•</span>
              <span className="referenceDetail__cont__header_text">
                {this.state.references[this.state.index].person_name.text}
              </span>
              <span className="referenceDetail__cont__header_text">-</span>
              <span className="referenceDetail__cont__header_text">
                {this.state.references[this.state.index].person_position.text}
              </span>
            </div>

            <div className="referenceDetail__cont__content">
              {this.state.references[this.state.index].reference_detail_text_1.raw.map((paragraph, i) => {
                return <p key={i}>{paragraph.text}</p>;
              })}

              <h3>{this.state.references[this.state.index].reference_detail_heading_1.text}</h3>
              <ul>
                {this.state.references[this.state.index].reference_detail_list_1.raw.map((paragraph, i) => {
                  return <li key={i}>{paragraph.text}</li>;
                })}
              </ul>

              <h3>{this.state.references[this.state.index].reference_detail_heading_2.text}</h3>
              <ul>
                {this.state.references[this.state.index].reference_detail_list_2.raw.map((paragraph, i) => {
                  return <li key={i}>{paragraph.text}</li>;
                })}
              </ul>
            </div>
          </div>

          <div className="referenceDetail__close"></div>
        </div>
      </section>
    );
  }
}
referenceSectionComponent.contextType = ContextProviderComponent;
export default referenceSectionComponent;
