/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import "./layout.scss";

import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, navigate } from "gatsby";
import $ from "jquery";
import ReactFullpage from "@fullpage/react-fullpage";
import CookieConsent from "react-cookie-consent";

import { isMobile, isTablet, isIPad13, isDesktop, isBrowser, isMobileOnly, withOrientationChange } from "react-device-detect";

import SEO from "./seo";

import Header from "./header/header";
import MenuBar from "./menubar/menubar";
import Footer from "./footer/footer";

import { ContextProviderComponent } from "./contextProviderComponent";
import ModalEmbeededComponent from "../components/modalEmbeededComponent/modalEmbeededComponent";
import { Helmet } from "react-helmet";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "transparent",
      height: "130px",
      activeSection: 0,
      showAfterSliderSections: false,
      showHeroSection: true,
      isLoading: true,
      showForm: "none",
      fullPage: !!this.props.fullPage
    };
  }

  componentDidMount() {
    this.handleWindowSizeChange();

    window.scrollTo(0, 0);

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);

    if (isMobileOnly || isTablet || isIPad13) {
      $(window).scroll(function() {
        var height = $(window).scrollTop();

        if (height > 75) {
          $("header").css({
            background: "#17181D"
          });
        } else {
          $("header").css({
            background: "transparent"
          });
        }
      });
    } else if (isDesktop && !isTablet && !isIPad13 && !this.state.fullPage) {
      $(window).scroll(function() {
        var height = $(window).scrollTop();

        if (height > 75) {
          $("header").css({
            background: "#17181D",
            height: "75px"
          });
        } else {
          $("header").css({
            background: "transparent",
            height: "130px"
          });
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  callBackForCalendlyModalShow = (show) => {
    this.setState({ showForm: show });
  };
  callBackForShowAfterSliderSections = (show) => {
    this.setState({ showAfterSliderSections: show });
  };
  callBackForShowHeroSection = (show) => {
    this.setState({ showHeroSection: show });
  };
  callBackForActiveSection = (newVal) => {
    this.setState({ activeSection: newVal });
  };

  onLeave(origin, destination, direction) {
    if (destination.index === 0) {
      $("header").css({
        background: "transparent",
        height: "130px"
      });
    } else {
      $("header").css({
        background: "#17181D",
        height: "75px"
      });
    }
  }

  navigateClickCallback = (index) => {
    switch (index) {
      case 0:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/warumsmartwealth");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/whysmartwealth");
        }
        break;
      case 1:
        navigate("/solutions/");
        break;
      case 2:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/uberuns");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/aboutus");
        }
        break;
      case 3:
        navigate("/insights/");
        break;
      case 4:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/kontaktiereuns/");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/contactus");
        }
        break;
      case 5:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/blog");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/blog");
        }
        break;
      case 8:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/datenschutzbestimmungen");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/privacypolicy");
        }
        break;
      case 9:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/kundeninformation");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/customerinformation");
        }
        break;
      case 10:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/impressum");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/imprint");
        }
        break;
      case 11:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/privatkunden");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/privateclient");
        }
        break;
      case 12:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/institutionellekunden");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/institutionalclients");
        }
        break;
      case 13:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/b2b");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb/b2b");
        }
        break;
      case 99:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb");
        }
        break;
      default:
        if (this.props.activeDocMeta.lang == "de-ch") {
          navigate("/");
        } else if (this.props.activeDocMeta.lang == "en-gb") {
          navigate("/en-gb");
        }
        break;
    }
  };

  navigateToBlogPost = (title) => {
    if (this.props.activeDocMeta.lang == "de-ch") {
      navigate("/blog/" + title);
    } else if (this.props.activeDocMeta.lang == "en-gb") {
      navigate("/en-gb/blog/" + title);
    }
  };

  render() {
    return (
      //this.state.isLoading ?
      //<LoadingComponent /> :
      <>
        <ContextProviderComponent.Provider
          value={{
            lang: this.props.activeDocMeta.lang,
            state: this.state,
            callBackForActiveSection: this.callBackForActiveSection,
            callBackForCalendlyModalShow: this.callBackForCalendlyModalShow,
            navigateClickCallback: this.navigateClickCallback
          }}>
          <Helmet>
            <html lang={this.props.activeDocMeta.lang == "en-gb" ? "en" : "de"} />
          </Helmet>
          <SEO />

          <CookieConsent
            location="bottom"
            buttonText="OK"
            buttonClasses="cookieButton"
            cookieName="smartwealthCookie"
            style={{ background: "#FFFFFF", color: "#17181D" }}
            expires={30}>
            {this.props.body.data.cookie_consent &&
              this.props.body.data.cookie_consent.raw.map((paragraph, i) => {
                return <p key={i}>{paragraph.text}</p>;
              })}
          </CookieConsent>

          <ModalEmbeededComponent callBackForCalendlyModalShow={this.callBackForCalendlyModalShow} />

          <Header
            callBackForCalendlyModalShow={this.callBackForCalendlyModalShow}
            callBackForActiveSection={this.callBackForActiveSection}
            activeSection={this.state.activeSection}
            headerBackground={this.state.backgroundColor}
            height={this.state.height}
            body={this.props.body}
            activeDocMeta={this.props.activeDocMeta}
            progressBar={this.props.progressBar}
          />

          <MenuBar
            headerBackground={this.state.backgroundColor}
            height={this.state.height}
            body={this.props.body}
            activeDocMeta={this.props.activeDocMeta}
          />

          {isDesktop && !isTablet && !isIPad13 && this.state.fullPage && (
            <ReactFullpage
              sliderRef={(el) => (this.sliderRef = el)}
              //fullpage options
              licenseKey={"OPEN-SOURCE-GPLV3-LICENSE"}
              scrollingSpeed={600}
              scrollOverflow={true}
              autoScrolling={true}
              //css3 = {true}
              //easingcss3 = {"ease-in-out"}
              onLeave={this.onLeave.bind(this)}
              //scrollBar={true}
              //scrollHorizontally={false}
              //Navigation
              menu={"#menu"}
              lockAnchors={false}
              //anchors={['firstPage', 'secondPage']}
              navigation={true}
              navigationPosition={"right"}
              //navigationTooltips={['firstSlide', 'secondSlide']}
              showActiveTooltip={false}
              slidesNavigation={true}
              slidesNavPosition={"bottom"}
              keyboardScrolling={true}
              // normalScrollElements={"#outstandingResults"}
              render={({ state, fullpageApi }) => {
                return (
                  <ReactFullpage.Wrapper>
                    <main>
                      {this.props.children}

                      <div className="section">
                        <Footer
                          body={this.props.bodyFooter}
                          callBackForActiveSection={this.callBackForActiveSection}
                          activeSection={this.state.activeSection}
                          activeDocMeta={this.props.activeDocMeta}
                        />
                      </div>
                    </main>
                  </ReactFullpage.Wrapper>
                );
              }}
            />
          )}

          {isDesktop && !isTablet && !isIPad13 && !this.state.fullPage && (
            <main>
              {this.props.children}

              <Footer
                body={this.props.bodyFooter}
                callBackForActiveSection={this.callBackForActiveSection}
                activeSection={this.state.activeSection}
                activeDocMeta={this.props.activeDocMeta}
              />
            </main>
          )}

          {(isMobileOnly || isTablet || isIPad13) && (
            <main>
              {this.props.children}

              <Footer
                body={this.props.bodyFooter}
                callBackForActiveSection={this.callBackForActiveSection}
                activeSection={this.state.activeSection}
                activeDocMeta={this.props.activeDocMeta}
              />
            </main>
          )}
        </ContextProviderComponent.Provider>
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default withOrientationChange(Layout);
