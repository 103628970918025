module.exports = {
  
    // The repo name for your Prismic repository (found in the url of your repo: https://your-repo-name.prismic.io/)
    prismicRepo: 'smartwealth',
  
    // Release ID to build (requires access)
    releaseID: '',
  
    // access token to access the release
    accessToken: 'MC5ZSms3M3hFQUFDSUFNUXlN.77-977-9fXDvv70s77-977-9OO-_ve-_vQXvv70H77-9Ke-_ve-_vX3vv70Y77-977-977-9EB_vv73vv73vv70wTQs',
  
    // Language configuration in your Prismic repository: Settings / Translations & locals
    defaultLanguage: 'de-ch',
    langs: ['de-ch', 'en-gb'],
  }