import React from "react";
import $ from "jquery";
import "./ourhistoryComponent.scss";
import Observer from "react-intersection-observer";

import { Link } from "gatsby";
import lottie from "lottie-web";
import { TweenMax as TM, Back, Power1, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import { isMobile, isTablet, isDesktop } from "react-device-detect";

import prevIcon from "../../../images/icons/prev.svg";
import nextIcon from "../../../images/icons/next.svg";
import smallTriangelImg from "../../../images/icons/small_triangel_white.svg";

import year1999 from "./1999.json";
import year1999to2001 from "./1999-2001.json";
import year2001to2001 from "./2001-2001.json";
import year2001to2002 from "./2001-2002.json";
import year2002to2009 from "./2002-2009.json";
import year2009to2012 from "./2009-2012.json";
import year2012to2016 from "./2012-2016.json";
import year2016to2019 from "./2016-2019.json";
import year2019to2020 from "./2019-2020.json";
import year2020to2021 from "./2020-2021.json";
import year2021to2022 from "./2021-2022.json";

let animObjs = [null, null, null, null, null, null, null];

class OurhistoryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text,
      sectionName: this.props.slice.primary.section_name.text,
      items: this.props.slice.items,
      index: 0,
      wait: false,
      playedOnce: false
    };
  }

  componentDidUpdate() {
    $(".ourhistorySection__cont_controls--prev")
      .off()
      .on(
        "click",
        function(event) {
          event.preventDefault();
          event.stopPropagation();
          if (!this.setState.wait) {
            this.setState({ wait: true });
            this.slidePrevious();
          }
        }.bind(this)
      );

    $(".ourhistorySection__cont_controls--next")
      .off()
      .on(
        "click",
        function(event) {
          event.preventDefault();
          event.stopPropagation();
          if (!this.setState.wait) {
            this.setState({ wait: true });
            this.slideNext();
          }
        }.bind(this)
      );
  }

  componentDidMount() {
    this.setState({ index: 0 });

    ///////// LOTTIE 1999 /////////

    animObjs[0] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_0")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year1999 // the path to the animation json
    });

    ///////// LOTTIE 1999 TO 2001 /////////

    animObjs[1] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_1")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year1999to2001 // the path to the animation json
    });

    ///////// LOTTIE 2001 TO 2001 /////////

    animObjs[2] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_2")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2001to2001 // the path to the animation json
    });

    ///////// LOTTIE 2001 TO 2002 /////////

    animObjs[3] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_3")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2001to2002 // the path to the animation json
    });

    ///////// LOTTIE 2002 TO 2009 /////////

    animObjs[4] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_4")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2002to2009 // the path to the animation json
    });

    ///////// LOTTIE 2009 TO 2012 /////////

    animObjs[5] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_5")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2009to2012 // the path to the animation json
    });

    ///////// LOTTIE 2012 TO 2016 /////////

    animObjs[6] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_6")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2012to2016 // the path to the animation json
    });

    ///////// LOTTIE 2016 TO 2019 /////////

    animObjs[7] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_7")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2016to2019 // the path to the animation json
    });

    ///////// LOTTIE 2019 TO 2020 /////////

    animObjs[8] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_8")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2019to2020 // the path to the animation json
    });

    ///////// LOTTIE 2020 TO 2021 /////////

    animObjs[9] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_9")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2020to2021 // the path to the animation json
    });

    ///////// LOTTIE 2021 TO 2022 /////////

    animObjs[10] = lottie.loadAnimation({
      container: document.getElementsByClassName("ourhistorySection__cont_lottie_10")[0], // the dom element that will contain the animation
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: year2021to2022 // the path to the animation json
    });
  }

  slidePrevious() {
    let oldIndex = this.state.index;
    let newIndex = this.state.index - 1;

    animObjs[oldIndex].setDirection(-1);
    animObjs[oldIndex].play();

    if (newIndex < 0) {
      newIndex += this.state.items.length;
    }

    TM.to($(".ourhistorySection__cont_text"), 0.8, {
      x: "100%",
      opacity: 0,
      ease: Back.easeInOut,
      delay: 0,
      onStart: function() {},
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to($(".ourhistorySection__cont_text"), 0.05, {
          x: "-100%",
          ease: Back.easeOut,
          onComplete: function() {
            TM.to($(".ourhistorySection__cont_text"), 0.8, {
              x: "0%",
              opacity: 1,
              ease: Back.easeInOut,
              delay: 0,
              onComplete: function() {
                $(".ourhistorySection__cont_lottie_" + oldIndex).css("display", "none");
                $(".ourhistorySection__cont_lottie_" + newIndex).css("display", "block");
                this.setState({ wait: false });
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
  }

  slideNext() {
    let oldIndex = this.state.index;
    let newIndex = (this.state.index + 1) % this.state.items.length;

    TM.to($(".ourhistorySection__cont_text"), 0.8, {
      x: "-100%",
      opacity: 0,
      ease: Back.easeInOut,
      delay: 0,
      onStart: function() {
        $(".ourhistorySection__cont_lottie_" + oldIndex).css("display", "none");
        $(".ourhistorySection__cont_lottie_" + newIndex).css("display", "block");
        animObjs[newIndex].setDirection(1);
        animObjs[newIndex].goToAndPlay(0);
      },
      onComplete: function() {
        this.setState({ index: newIndex });

        TM.to($(".ourhistorySection__cont_text"), 0.05, {
          x: "100%",
          ease: Back.easeOut,
          onComplete: function() {
            TM.to($(".ourhistorySection__cont_text"), 0.8, {
              x: "0%",
              opacity: 1,
              ease: Back.easeInOut,
              delay: 0,
              onComplete: function() {
                this.setState({ wait: false });
                //animObjs[oldIndex].destroy();
              }.bind(this)
            });
          }.bind(this)
        });
      }.bind(this)
    });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });

      if (!this.state.playedOnce) {
        animObjs[0].goToAndPlay(0);
        this.setState({ playedOnce: true });
      }
    }
  }

  render() {
    return (
      <section id="ourhistorySection" className="ourhistorySection">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="ourhistorySection__cont">
            <div className="ourhistorySection__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
              <span>{this.state.sectionName}</span>
            </div>

            <div className="ourhistorySection__cont_content">
              <div className="ourhistorySection__cont_text">
                <h5>{this.state.items[this.state.index].item_title_years.text}</h5>
                {this.state.items[this.state.index].item_text.raw.map((paragraph, i) => {
                  return <p key={i}>{paragraph.text}</p>;
                })}
                {false && this.state.items[this.state.index].item_button_text.text && (
                  <Link to="#" className="textbutton">
                    <p>
                      {this.state.items[this.state.index].item_button_text.text}
                      <img alt="smartwealth triangle icon" src={smallTriangelImg}></img>
                    </p>
                  </Link>
                )}
              </div>

              <div className="ourhistorySection__cont_controls">
                {this.state.index != 0 && (
                  <img
                    className="ourhistorySection__cont_controls--prev"
                    alt="smartwealth previous arrow icon"
                    src={prevIcon}></img>
                )}
                {this.state.index != this.state.items.length - 1 && (
                  <img alt="smartwealth next arrow icon" className="ourhistorySection__cont_controls--next" src={nextIcon}></img>
                )}
              </div>
            </div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_0"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_1"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_2"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_3"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_4"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_5"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_6"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_7"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_8"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_9"></div>

            <div className="ourhistorySection__cont_lottie ourhistorySection__cont_lottie_10"></div>
          </div>
        </Observer>
      </section>
    );
  }
}
OurhistoryComponent.contextType = ContextProviderComponent;
export default OurhistoryComponent;
