import React from "react";
import $ from "jquery";
import "./unserteamComponent.scss";
import Observer from "react-intersection-observer";
import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

import { Link } from "gatsby";

import { isBrowser, isDesktop, isMobile, isTablet, isMobileOnly } from "react-device-detect";

import closeImg from "../../../images/icons/hide.svg";

class UnserteamComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text || "",
      sectionName: this.props.slice.primary.section_name.text || "",
      sectionTitle: this.props.slice.primary.section_title.text || "",
      sectionText: this.props.slice.primary.section_text.text || "",
      members: this.props.slice.items || [],
      index: 0
    };
  }

  componentDidMount() {
    if (isDesktop || isTablet) {
      $(".unserteamSection__cont_left__content__members_item").on(
        "click",
        function(event) {
          let memberIndex = $(event.target)
            .closest(".unserteamSection__cont_left__content__members_item")
            .attr("data");
          this.showDetail(memberIndex);
        }.bind(this)
      );

      $(".unserteamDetail__close, .unserteamDetail .closeBtn").on(
        "click",
        function(event) {
          this.hideDetail();
        }.bind(this)
      );
    }
  }

  componentWillUnmount() {
    this.enableScroll();
  }

  showDetail(memberIndex) {
    TM.to($("header"), 0.4, {
      y: "-100%",
      opacity: 0,
      ease: Power2.easeOut,
      delay: 0,
      onStart: function() {
        this.setState({ index: memberIndex });
        this.disableScroll();
        $("#fp-nav").fadeOut();
      }.bind(this),
      onComplete: function() {
        $(".unserteamDetail").fadeIn();
        TM.to($(".unserteamDetail__cont"), 0.4, {
          x: "0px",
          ease: Power2.easeOut,
          delay: 0.2
        });
      }
    });
  }

  hideDetail() {
    TM.to($(".unserteamDetail__cont"), 0.4, {
      x: "-100%",
      ease: Power2.easeOut,
      onComplete: function() {
        $(".unserteamDetail").fadeOut();
        TM.to($("header"), 0.4, {
          y: "0%",
          opacity: 1,
          ease: Power2.easeOut,
          onComplete: function() {
            this.enableScroll();
            $("#fp-nav").fadeIn();
          }.bind(this)
        });
      }.bind(this)
    });
  }

  disableScroll() {
    if (isDesktop && !isTablet) {
      $("html, body").bind("wheel", function() {
        return false;
      });
    } else {
      $("html, body").css("overflow", "hidden");
    }
  }

  enableScroll() {
    if (isDesktop && !isTablet) {
      $("html, body").off("wheel");
    } else {
      $("html, body").css("overflow", "");
    }
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(entry.target.firstChild, 0.8, {
        opacity: 1,
        ease: Power2.easeInOut,
        delay: 0.0
      });
    }
  }

  render() {
    return (
      <section id="unserteamSection" className="unserteamSection">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="unserteamSection__cont">
            <div className="unserteamSection__cont_left">
              <div className="unserteamSection__cont_left__pageNumber">
                <span>{this.state.sectionNumber}</span>
              </div>
              <div className="unserteamSection__cont_left__content">
                <span>
                  {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                    <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                  ) : (
                    ""
                  )}
                  {this.state.sectionName}
                </span>
                {this.state.sectionTitle && <h2>{this.state.sectionTitle}</h2>}
                {this.state.sectionText && <p>{this.state.sectionText}</p>}
                <div className="unserteamSection__cont_left__content__members">
                  {this.state.members.map((member, i) => {
                    return (
                      <div key={i} data={i} className="unserteamSection__cont_left__content__members_item">
                        <img src={member.member_image.url} alt="smartwealth member image"></img>
                        <h4>{member.member_name.text}</h4>
                        <h5>{member.member_position.text}</h5>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Observer>

        <div className="unserteamDetail">
          <div className="unserteamDetail__cont">
            <div className="unserteamDetail__cont__header">
              <img src={closeImg} className="closeBtn" alt="smartwealth close icon"></img>
              <span className="unserteamDetail__cont__header_text unserteamDetail__cont__header_text-colorful">
                {this.state.sectionName}
              </span>
              <span className="unserteamDetail__cont__header_text">•</span>
              <span className="unserteamDetail__cont__header_text">{this.state.members[this.state.index].member_name.text}</span>
              <span className="unserteamDetail__cont__header_text">-</span>
              <span className="unserteamDetail__cont__header_text">
                {this.state.members[this.state.index].member_position.text}
              </span>
            </div>

            <div className="unserteamDetail__cont__content">
              <h3>{this.state.members[this.state.index].member_name.text}</h3>

              {this.state.members[this.state.index].member_text.raw.map((paragraph, i) => {
                return <p key={i}>{paragraph.text}</p>;
              })}
            </div>
          </div>

          <div className="unserteamDetail__close"></div>
        </div>
      </section>
    );
  }
}
UnserteamComponent.contextType = ContextProviderComponent;
export default UnserteamComponent;
