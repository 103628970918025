import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import { ContextProviderComponent } from "../contextProviderComponent";

import "./modalEmbeededComponent.scss";

import logo from "../../images/logo/logo.svg";
import closeButton from "../../images/icons/close.svg";

//import { trackCustomEvent, OutboundLink } from "gatsby-plugin-google-analytics";
//import ReactGA from "react-ga";

import CalendlyComponent from "../calendlyComponent/calendlyComponent";

import { TweenMax, Power1, Power2 } from "gsap/all";

import CSSPlugin from "gsap/CSSPlugin";

const C = CSSPlugin;

class ModalEmbeededComponent extends React.Component {
  constructor(props) {
    super(props);

    //this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.modalEmbeeded = React.createRef();
  }

  showModal() {

    document.querySelector("html").classList.add("stop-scrolling");

    $("#modalEmbeeded").fadeIn(function() {
      TweenMax.to(
        $('.calendly-inline-widget'),
          0.5,
          { 
            delay: 0.5,
            opacity: 1,
            ease: Power1.easeOut,
          }
      );
    });
    
    
    //ReactGA.pageview(window.location.pathname + "calendlyModal/open");

    /*trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Opened schedule form",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "EXP Redesign - Opened schedule form",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 1
    });*/

  }

  closeModal() {

    document.querySelector("html").classList.remove("stop-scrolling");

    $("#modalEmbeeded").fadeOut();

  }

  componentDidUpdate() {
    this.context.state.showForm == "none" ? this.closeModal() : this.showModal();
  }

  render() {
    return (
      <div
        className="modalEmbeeded"
        id="modalEmbeeded"
        style={{ display: `${this.props.show}` }}
      >
        <div className="modalEmbeeded-header">
          <div>
            <Link to="/" target="_blank">
              <img alt="smartwealth logo" className="" src={logo}></img>
            </Link>
          </div>
          <div className="close-btn" onClick={() => this.context.callBackForCalendlyModalShow('none')}>
            <img alt="cross close" src={closeButton}></img>
          </div>
        </div>

        <div className="modalEmbeeded-body">
          <CalendlyComponent account="smartwealth-support" eventName={this.context.lang == 'de-ch' ? "smart-wealth-besprechung" : "smart-wealth-consultation"} />
        </div>
      </div>
    );
  }
}

ModalEmbeededComponent.contextType = ContextProviderComponent;
export default ModalEmbeededComponent;
