import React from "react";
import "./hoverCardComponent.scss";
import Observer from "react-intersection-observer";
import { isBrowser, isMobileOnly, isMobile, isTablet, isDesktop } from "react-device-detect";
import lineVectorImg from "../../../images/b2b/hoverCardVectorLine.svg";

import { TweenMax as TM, Power1, Power2, Power3, gsap } from "gsap/all";
import { ContextProviderComponent } from "../../contextProviderComponent";

class HoverCardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionNumber: this.props.slice.primary.section_number.text,
      sectionName: this.props.slice.primary.section_title.text,
      title: this.props.slice.primary.title.text,
      aboveCTAText: this.props.slice.primary.above_cta_text.text,
      ctaText: this.props.slice.primary.cta_text.text,
      items: this.props.slice.items,
      itemsStates: Array(this.props.slice.items.length).fill({ hover: false })
    };
    this.cardsRefs = [this.props.slice.items.length];
  }

  componentDidMount() {
    this.setState({ index: 0 });
    gsap.set([this.title, this.rightCont], { opacity: 0, y: 50 });
    this.cardsRefs.forEach((item) => {
      gsap.set(item, { opacity: 0, y: 50 });
    });
  }

  handleContentVisibility(inView, entry) {
    if (inView) {
      TM.to(this.title, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.4
      });
      TM.to(this.rightCont, 1, {
        opacity: 1,
        y: 0,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TM.to(this.vectorOverlay, 1, {
        left: "100vw",
        ease: Power1.easeOut,
        delay: 0.6
      });
      var delay = 0.2;
      this.cardsRefs.forEach((item) => {
        delay = delay + 0.2;
        TM.to(item, 0.5, {
          opacity: 1,
          y: 0,
          zIndex: 1,
          ease: Power3.easeOut,
          delay: delay
        });
      });
    }
  }

  mouseEnter = (index) => {
    let itemsStates = [...this.state.itemsStates];
    itemsStates.fill({ hover: false });
    let item = {
      ...itemsStates[index],
      hover: true
    };
    itemsStates[index] = item;
    this.setState({ itemsStates });
  };

  mouseLeave = () => {
    let itemsStates = [...this.state.itemsStates];
    itemsStates.fill({ hover: false });
    this.setState({ itemsStates });
  };

  render() {
    return (
      <section id="hoverCardComponent" className="hoverCardComponent">
        <Observer threshold="0.1" onChange={(inView, entry) => this.handleContentVisibility(inView, entry)} className="wrapper">
          <div className="hoverCardComponent__cont">
            <div className="hoverCardComponent__cont_pageNumber">
              <span>{this.state.sectionNumber}</span>
            </div>
            <div className="hoverCardComponent__cont_contentWrapper">
              <div className="hoverCardComponent__cont_contentWrapper_content">
                <div className="hoverCardComponent__cont_contentWrapper_content_topCont">
                  <div className="hoverCardComponent__cont_contentWrapper_content_topCont_left">
                    <span className="hoverCardComponent__cont_contentWrapper_content_topCont_left_pageTitle">
                      {isMobileOnly || (isTablet && window.innerHeight > window.innerWidth) ? (
                        <span style={{ marginRight: "24px" }}>{this.state.sectionNumber}</span>
                      ) : (
                        ""
                      )}
                      {this.state.sectionName}
                    </span>
                    <h2
                      ref={(ref) => {
                        this.title = ref;
                      }}
                      className="hoverCardComponent__cont_contentWrapper_content_topCont_left_title">
                      {this.state.title}
                    </h2>
                    <p style={{ marginTop: "16px" }}>{this.state.aboveCTAText}</p>
                  </div>
                  <div
                    ref={(ref) => {
                      this.rightCont = ref;
                    }}
                    className="hoverCardComponent__cont_contentWrapper_content_topCont_right">
                    <div
                      className="button button-hoverBlue hoverCardComponent__cont_contentWrapper_content_topCont_right_button"
                      onClick={() => this.context.navigateClickCallback(4)}>
                      <p>{this.state.ctaText}</p>
                    </div>
                  </div>
                </div>
                <div className="hoverCardComponent__cont_contentWrapper_content_cardsCont" onMouseLeave={this.mouseLeave}>
                  {this.state.items.map((card, index) => {
                    return (
                      <div
                        className="hoverCardComponent__cont_contentWrapper_content_cardsCont_card"
                        ref={(ref) => (this.cardsRefs[index] = ref)}
                        onMouseEnter={() => this.mouseEnter(index)}
                        onMouseLeave={() => this.mouseLeave(index)}>
                        <div
                          className="hoverCardComponent__cont_contentWrapper_content_cardsCont_card_normal"
                          style={{
                            backgroundImage: `url(${card.card_image.url})`,
                            opacity: this.state.itemsStates[index].hover == false ? 1 : 0
                          }}>
                          <div className="hoverCardComponent__cont_contentWrapper_content_cardsCont_card_normal_textCell">
                            <h3>{card.card_title.text}</h3>
                            <p>{card.card_description.text}</p>
                          </div>
                          <p className="hoverCardComponent__cont_contentWrapper_content_cardsCont_card_normal_iconCell">
                            {card.show_more_text.text}
                          </p>
                        </div>

                        <div
                          className="hoverCardComponent__cont_contentWrapper_content_cardsCont_card_hover"
                          style={{ opacity: this.state.itemsStates[index].hover == true ? 1 : 0 }}>
                          <div className="hoverCardComponent__cont_contentWrapper_content_cardsCont_card_hover_textCell">
                            <h3>{card.card_hover_title.text}</h3>
                            <p>{card.card_hover_description.text}</p>
                          </div>
                          <div className="hoverCardComponent__cont_contentWrapper_content_cardsCont_card_hover_list">
                            <p>{card.card_hover_item1.text}</p>
                            <p>{card.card_hover_item2.text}</p>
                            <p>{card.card_hover_item3.text}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="hoverCardComponent__cont_vector">
              <div
                ref={(ref) => {
                  this.vectorOverlay = ref;
                }}
                className="hoverCardComponent__cont_vector_overlay"></div>
              <img alt="smartwealth line vector icon" src={lineVectorImg} alt=""></img>
            </div>
          </div>
        </Observer>
      </section>
    );
  }
}

HoverCardComponent.contextType = ContextProviderComponent;
export default HoverCardComponent;
